import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block-list"
}
const _hoisted_2 = { id: "selectedTaskList" }
const _hoisted_3 = {
  key: 1,
  class: "spinner-grow spinner-grow-sm",
  role: "status"
}
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { for: "exampleInputEmail1" }
const _hoisted_9 = {
  class: "toggle-box position-relative pe-0",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorList = _resolveComponent("ErrorList")
  const _component_multiselect = _resolveComponent("multiselect")
  const _component_ToDoPhaseAddMemberIndividualTest = _resolveComponent("ToDoPhaseAddMemberIndividualTest")
  const _component_AppDeleteConfirmationModal = _resolveComponent("AppDeleteConfirmationModal")
  const _component_CheckAvaibilityByUser = _resolveComponent("CheckAvaibilityByUser")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_ErrorList, {
        errors: _ctx.state.ui.error.errorList.addMembersToTasks,
        hasError: _ctx.state.ui.error.hasError
      }, null, 8, ["errors", "hasError"]),
      (_ctx.tasks && _ctx.tasks.length)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode("ul", _hoisted_2, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tasks, (task, taskIndex) => {
                return (_openBlock(), _createBlock("li", {
                  key: `task${taskIndex}`
                }, _toDisplayString(task.name), 1))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.state.ui.isLoading.changeDetailsByEmail)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('loading1')), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode("div", _hoisted_7, [
            _createVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('invite_existing_member')), 1),
            _createVNode(_component_multiselect, {
              modelValue: _ctx.state.data.inviteToTask.existingMemberSelected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.data.inviteToTask.existingMemberSelected = $event)),
              options: _ctx.existingMembers,
              "custom-label": _ctx.nameWithEmail,
              "close-on-select": "true",
              "clear-on-select": true,
              placeholder: _ctx.$t('search_and_select'),
              "track-by": "email",
              onSelect: _ctx.changeDetailsByEmail
            }, null, 8, ["modelValue", "options", "custom-label", "placeholder", "onSelect"])
          ])
        ])
      ]),
      _createVNode("div", _hoisted_9, [
        (
        _ctx.state.data.inviteToTask.selectedMembers &&
        _ctx.state.data.inviteToTask.selectedMembers.length)
          ? (_openBlock(), _createBlock(_component_ToDoPhaseAddMemberIndividualTest, {
              key: 0,
              activeTab: _ctx.activeTab,
              type: 1,
              isShowDeleteMember: "true",
              isShowDeleteSlot: false,
              permissions: _ctx.permissions,
              relatedPermissions: _ctx.relatedPermissions,
              amountDepends: _ctx.amountDepends,
              userRole: _ctx.activeUserRole,
              roles: _ctx.roles,
              amountTypes: _ctx.amountTypes,
              members: _ctx.state.data.inviteToTask.selectedMembers,
              onSelectedMember: _ctx.selectedMemberFromTask,
              onSelectedMemberIndex: _ctx.selectedMemberIndex,
              onSelectedMemberForCheckAvaibility: _ctx.checkAvaibilityByUser,
              onDeleteRole: _ctx.deleteRole,
              onAddNewRole: _ctx.addNewRole,
              onAddNewAmountType: _ctx.addNewAmountType,
              onSelectedRoleForMember: _ctx.selectedRoleForMember,
              onSelectedAmountTypeForMember: _ctx.selectedAmountTypeForMember,
              onPermissionByUser: _ctx.permissionByUser
            }, null, 8, ["activeTab", "permissions", "relatedPermissions", "amountDepends", "userRole", "roles", "amountTypes", "members", "onSelectedMember", "onSelectedMemberIndex", "onSelectedMemberForCheckAvaibility", "onDeleteRole", "onAddNewRole", "onAddNewAmountType", "onSelectedRoleForMember", "onSelectedAmountTypeForMember", "onPermissionByUser"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode("button", {
        type: "button",
        class: "btn default-btn mr-2",
        disabled: !_ctx.hasValidLastEmail,
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.insertEmptyMember && _ctx.insertEmptyMember(...args)))
      }, "Add Another Member", 8, ["disabled"]),
      _createVNode("button", {
        type: "button",
        class: "btn default-btn mr-2",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addMembersToTasks && _ctx.addMembersToTasks(...args))),
        disabled: !_ctx.memberFilteredSlotsValid
      }, _toDisplayString(_ctx.state.ui.isPostLoading.addMembersToTasks ? _ctx.$t('loading1') : _ctx.$t('submit')), 9, ["disabled"]),
      _createVNode("button", {
        type: "button",
        class: "btn secondary-btn",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.hideAddMemberToTaskModal && _ctx.hideAddMemberToTaskModal(...args)))
      }, _toDisplayString(_ctx.$t('cancel')), 1)
    ]),
    (_ctx.state.data.showDeleteConfirmationModal)
      ? (_openBlock(), _createBlock(_component_AppDeleteConfirmationModal, {
          key: 0,
          showConfirmationModal: _ctx.state.data.showDeleteConfirmationModal,
          onButtonTrigger: _ctx.removeSelectedMember,
          onHideConfirmation: _ctx.hideConfirmation,
          message: _ctx.state.data.deleteModalMessage
        }, null, 8, ["showConfirmationModal", "onButtonTrigger", "onHideConfirmation", "message"]))
      : _createCommentVNode("", true),
    (_ctx.state.data.showAvailibilityModal)
      ? (_openBlock(), _createBlock(_component_CheckAvaibilityByUser, {
          key: 1,
          activeTab: _ctx.activeTab,
          phaseDetails: _ctx.phaseDetails,
          tasks: _ctx.tasks,
          startDate: _ctx.customDates.startDate,
          endDate: _ctx.customDates.endDate,
          isActive: _ctx.state.data.showAvailibilityModal,
          userDetails: _ctx.state.data.selectedUser,
          selectedEmail: _ctx.state.data.selectedEmail,
          onCreatedEvent: _ctx.createdEvent,
          onDeletedEvent: _ctx.deletedEvent,
          onHideAvaibilityModal: _ctx.hideAvaibilityModal
        }, null, 8, ["activeTab", "phaseDetails", "tasks", "startDate", "endDate", "isActive", "userDetails", "selectedEmail", "onCreatedEvent", "onDeletedEvent", "onHideAvaibilityModal"]))
      : _createCommentVNode("", true)
  ], 64))
}