
import { defineComponent, onMounted, reactive } from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import Button from "@/common/Button.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Multiselect from 'vue-multiselect';
import SuccessfulModalCustom from "./SuccessfulModalCustom.vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "ShareModal",
  props: {
    showModal: Boolean,
    selectedFiles: {
      type: Array,
    },
    // selectedFolder: {
    //   type: Array,
    //   required: true,
    // },
    // selectedFiles: {
    //   type: Array,
    //   required: true,
    // },
    // folderId: String,
    // fileId: String,
  },
  components: {
    Multiselect,
    ErrorList,
    Button,
    SuccessfulModalCustom
  },
  setup(props, { emit }) {
    // const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      data: {
        showSuccesCustomModal: false,
        successFulMessage: "",
        shareMessage: "" as any,
        folderRename: "" as any,
        buttonName: "Share",
        selectedFiles: [] as any,
        filesUUIDs: [] as any,
        folderList: [] as any,
        projectList: [] as any,
        userList: [] as any,
        channelList: [] as any,
        existingMember: {} as any,
        folderTitle: "folder",
        folderHeading: "No folders created",
        isFile: false,
        filter: {
          projectId: "" as any,
          channelId: "" as any,
        },

        invite: {
          users: [] as any,
        },
      },
      imageBaseUrl: "" as any,

      ui: {
        error: {
          hasError: false,
          errorList: {
            share: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
          getprojectList: false,
          getChannelByProject: false,
          getMemberListByProjectAndChannel: false,
        },
        isPostLoading: {
          shareFilesToMembers: false,
          createCommunication: false,
        },
      },
    });
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
    }
    function closeModal(data: any) {
      emit("close", data);
      $("#shareFileModal").modal("hide");
    }

    function validateShareFiles() {
      console.log("validation here");
      state.ui.error.errorList.share = [];
      state.ui.error.hasError = false;

      if (!state.data.filter.projectId) {
        state.ui.error.errorList.share.push("Project name is required.");
      }

      if (!state.data.filter.channelId) {
        state.ui.error.errorList.share.push("Channel name is required.");
      }
      if (!state.data.selectedFiles && !state.data.selectedFiles.length) {
        state.ui.error.errorList.share.push("At Least 1 file is required.");
      }

      if (state.data.filesUUIDs.length == 0) {
        state.ui.error.errorList.share.push("At Least 1 file is required.");
      }

      if (
        !state.ui.error.errorList.share &&
        !state.ui.error.errorList.share.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.share.length != 0) {
        state.ui.error.hasError = true;
        state.ui.isPostLoading.createCommunication = false;
      }
    }
    function shareFilesToMembers(message: any) {
      if(state.ui.isLoading.getChannelByProject) return false;
      // const filesUUIDs = [] as any;
      state.data.filesUUIDs = [];
      state.data.selectedFiles.forEach((item: any) => {
        if (item.isChecked) {
          state.data.filesUUIDs.push(item.uuid);
        }
      });
      validateShareFiles();
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        message: message,
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_ids: state.data.filesUUIDs,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sharing_type: "channel_basis",
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.filter.projectId ? state.data.filter.projectId.project.uuid : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.filter.channelId.uuid,
      };
      state.ui.isLoading.getChannelByProject = true;
      ethitransService
        .fileShare(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          state.ui.isLoading.getChannelByProject = false;
          closeModal(res.data.data);
          // closeSuccessCustomModal()
          // state.data.channelList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.share)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.share.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          
          state.data.selectedFiles = [];
        });
    }
    function createCommunication() {
      state.data.filesUUIDs = [];
      state.data.selectedFiles.forEach((item: any) => {
        if (item.isChecked) {
          state.data.filesUUIDs.push(item.uuid);
        }
      });
      validateShareFiles();
      if (
        state.ui.error.errorList.share.length != 0 ||
        state.ui.isPostLoading.createCommunication
      )
        return false;
      if (state.data.selectedFiles.length > 0) {
        state.data.isFile = true;
      }
      shareFilesToMembers(state.data.shareMessage);
      state.data.successFulMessage = t('file_shared_successfully');
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      return true;


      // const payLoad = {
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   channel_id: state.data.filter.channelId,
      //   message: state.data.shareMessage,
      //   type: "text",
      //   file:state.data.isFile,
      // };
      // state.ui.isPostLoading.createCommunication = true;
      // ethitransService
      //   .createCommunication(payLoad)
      //   .then((res: any) => {
      //     console.log("check Response >>> ", res.data.data);
      //     shareFilesToMembers(res.data.data.communication.id);

      //     // state.communicationList.push(res.data.data)

      //     // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
      //   })
      //   .catch((error: any) => {
      //     // console.log(error);
      //     common
      //       .sdCatchErr(error, state.ui.error.errorList.share)
      //       .then((res: any) => {
      //         state.ui.error.hasError = true;
      //         for (const [key, value] of Object.entries(res)) {
      //           if (Array.isArray(value)) {
      //             state.ui.error.errorList.share.push(value[0]);
      //           }
      //         }
      //       });
      //   })
      //   .finally(() => {
      //     // state.postLoading = false;
      //     // isuploded = true;
      //   });
      // // console.log("<<<MSG>>>>");
    }

    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.data.invite.users = state.data.invite.users.filter(
        (e: any) => e != item
      );
      state.data.userList.push(item);
    }
    function changeExistingMemberInForm() {
      if (state.data.invite.users.indexOf(state.data.existingMember) === -1) {
        state.data.invite.users.push(state.data.existingMember);
        // console.log(state.data.invite.users);
      }
      state.data.userList = state.data.userList.filter(
        (e: any) => e != state.data.existingMember
      );
      state.data.existingMember = {};
    }
    function getMemberListByProjectAndChannel() {
      state.ui.error.errorList.share = [];
      state.ui.error.hasError = false;
      // console.log("GET USER LIST");
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        // project_id: state.data.selectedChannelByProject.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.filter.channelId ? state.data.filter.channelId.uuid : "",
        // channel_id: state.data.channel.uuid,
      };
      console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isLoading.getMemberListByProjectAndChannel = true;
      ethitransService
        .channelMemberList(payLoad)
        .then((res: any) => {
          console.log("UserList Response Check:>>> ", res.data.data);
          state.data.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.share)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.share.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getMemberListByProjectAndChannel = false;
        });
    }
    function getChannelByProject() {
      state.ui.error.errorList.share = [];
      state.ui.error.hasError = false;
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.filter.projectId ? state.data.filter.projectId.project.uuid : "",
      };
      console.log("Project selected >>> ", payLoad)
      state.ui.isLoading.getChannelByProject = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          state.data.channelList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.share)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.share.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getChannelByProject = false;
        });
    }
    function getprojectList() {
      state.ui.error.errorList.share = [];
      state.ui.error.hasError = false;
      state.ui.isLoading.getprojectList = true;
      ethitransService
        .allProjectList('active')
        .then((res: any) => {
          if (res.data.data.projects && res.data.data.projects.length) {
            res.data.data.projects.forEach((item: any) => {
              item.name = item.project.name
            })
          }
          console.log("getprojectList Check:>>> ", res.data.data.projects);
          state.data.projectList = res.data.data.projects
            ? res.data.data.projects
            : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.share)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.share.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getprojectList = false;
        });
    }
    onMounted(() => {
      if (props.showModal) {
        // alert("SHAREMODAL")
        $("#shareFileModal").modal("show");
        state.imageBaseUrl = config.imageBaseUrl;
        state.data.selectedFiles = props.selectedFiles;
        getprojectList();
      }
    });

    return {
      state,
      closeModal,
      getChannelByProject,
      getprojectList,
      getMemberListByProjectAndChannel,
      changeExistingMemberInForm,
      removeSelectedMember,
      shareFilesToMembers,
      validateShareFiles,
      createCommunication,
      closeSuccessCustomModal,
      // deleteSelected,deleteFolder,deleteFile,
    };
  },
});
