import { vModelText as _vModelText, withKeys as _withKeys, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-wrap" }
const _hoisted_2 = { class: "input-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode("input", {
        type: "text",
        class: "form-control",
        placeholder: _ctx.$t('search_by_file_names_or_tags'),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.data.searchByTag = $event)),
        onKeyup: [
          _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.searchFileTags && _ctx.searchFileTags(...args))),
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.searchFileTags && _ctx.searchFileTags(...args)), ["enter"]))
        ]
      }, null, 40, ["placeholder"]), [
        [_vModelText, _ctx.state.data.searchByTag]
      ]),
      _createVNode("a", {
        href: "javascript:void(0)",
        class: "btn default-btn",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.searchFileTags && _ctx.searchFileTags(...args)))
      }, _toDisplayString(_ctx.$t('search')), 1)
    ])
  ]))
}