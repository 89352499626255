import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-list mt-3"
}
const _hoisted_2 = { class: "block-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hasError && _ctx.errors.length)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("p", null, _toDisplayString(_ctx.$t('field_problems')), 1),
        _createVNode("div", _hoisted_2, [
          _createVNode("ul", null, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.errors, (error, errorIndex) => {
              return (_openBlock(), _createBlock("li", { key: errorIndex }, _toDisplayString(error), 1))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}