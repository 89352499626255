
import { useStore } from "vuex";
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { authService } from "@/login/Services/AuthService";
import { validationService } from "@/common/ValidationService";
import { useRouter, useRoute } from "vue-router";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import { signOut } from "@/common/google";
// import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import { useCookie } from 'vue-cookie-next'
import AppEmailAndPhoneNumberValidate from "@/components/commonDesign/AppEmailAndPhoneNumberValidate.vue";


// import OTP from "@/components/modal/OTP.vue";

import SignUpWithOtp from "@/login/SignUpWithOtp.vue";
import AppOTPInput from "@/components/modal/AppOTPInput.vue";
import CounterComponent from "@/components/modal/CounterComponent.vue";
import { initializeSocket } from '@/socket';

import { data } from "jquery";

// import googleAuth from '@/common/services/googleAuth'
//  import GSignInButton from 'vue-google-signin-button'
// import GoogleSignInButton from 'vue-google-signin-button-directive'

export default defineComponent({
  name: "LoginComponentOTP",
  components: {
    CounterComponent,
    // OTP,
    SignUpWithOtp,
    AppOTPInput,
    AppEmailAndPhoneNumberValidate,
    // SuccessfulModal,
    ErrorList, // googleAuth
    // facebookLogin
  },
  setup() {
    const {
      query: { uuid },
    } = useRoute();
    const store = useStore();
    const clearInputValue = ref(false);
    const enteredValue = ref('');
    const errorMessage = ref('');
    const cookie = useCookie(); // ref link https://www.npmjs.com/package/vue-cookie-next
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      imageBaseUrl: "",
      data: {
        validInput: false,
        setCounter: 90,
        activeTab: "login",
        isPrimaryEmail: false,
        loginInBy: "",
        loginOTP: "",
        otp: "",
        title: 'Sign in',
        resendInvitationError: "",
        imageBaseUrl: "",
        successFulMessage: "",
        rememberPassword: false,
        isNormalLogin: false,
        showSuccesCustomModal: false,
        showReSendInvitation: false,
        showResenVerificationMail: false,
        ChannelId: "" as any,
        isPasswordShow: false,
        showSuccesModal: false,

      },
      passCode: {
        email: " ",
        otp: "",
        isSendOtp: false,
        error: [] as any,
        errorLogin: [] as any,
        shortReference: "" as any,
        resEmail: "" as any,
        resMobile: "" as any,
        timeCount: 200,
        successMessage: "" as any,
        showButton: false,
      },
      invitationLinks: {
        isSent: false,
        successMessage: "" as any,
        show: false,
        email: ""
      },
      ui: {
        disabled: {
          primary: false,
        },
        showUI:
        {
          changeInput: false,
          showOTP: false,
          counter: false,
          resendOTP: false,
          otp: false,
        },
        error: {
          errorList: {
            resendInvitationToNewEmail: [] as any,
            loginToAccount: [] as any,
          },
          hasError: false,
        },
        postLoading: {
          tokenLogin: false,
          checkOtpToLogin: false,
          getOTPForLogin: false,
          resendInvitationUuid: false,
          processRegistration: false,
        },
      },
      errorList: [] as any,
      registrationVerified: false,
      isErrorStatus: false,
      userName: "" as any,
      token: "" as any,
      channelId: "" as any,
      userPassword: "" as any,
      postLoading: false,
      name: "",
      email: "",
      personalID: "",
      picture: "",
      FB: Object as any,
      isConnected: false,
      auth2: {},
      googleUser: {} as any,
      googleSignInParams: {
        /* eslint-disable @typescript-eslint/class-name-casing */
        /* eslint-disable @typescript-eslint/camelcase */
        client_id:
          "268712205523-dpbrhqpqu6cpbjdmp83ia9a7nhk2vu5k.apps.googleusercontent.com",
      },
    });

    const handleError = (error: any) => {
      errorMessage.value = error; // Capture the error emitted by the child
    };
    const handleClear = () => {
      enteredValue.value = ''; // Clear the entered value when input is cleared
      errorMessage.value = ''; // Optionally clear the error message
      state.ui.error.errorList.loginToAccount = [];
      state.ui.error.hasError = false;
      clearInputValue.value = true;
    };
    function setActiveTab(tab: string) {
      state.data.activeTab = tab;
      if (tab === 'login') {
        console.log("CHECK TAB IF>>> ", tab)
        // const createTab = document.getElementById('nav-Create-tab');
        // if (createTab) {
        //   createTab.click();
        // }
        state.ui.error.errorList.loginToAccount = [];
        state.ui.error.hasError = false;
        handleClear();
        state.ui.disabled.primary = false;
      } else {
        // console.log("CHECK TAB  ELSE>>> ", tab)
        // const createTab = document.getElementById('nav-Create-tab');
        // if (createTab) {
        //   createTab.click();
        // }
      }

    }
    const buttonhasValidInput = computed(() => {
      if (!enteredValue.value) return false;
      return true;
      // return state.data.validInput;
    });


    const userName = computed(() => {
      if (localStorage.getItem("current_google_user_image")) {
        return localStorage.getItem("current_google_user_image");
      } else {
        return state.userName;
      }
    });
    const userError = computed(() => {
      if (localStorage.getItem("current_google_user_error")) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.ui.error.hasError = true;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.ui.error.hasError = false;
      }

      return state.ui.error.errorList.resendInvitationToNewEmail
        ? localStorage.getItem("current_google_user_error")
        : state.ui.error.errorList.resendInvitationToNewEmail;
    });
    state.ui.error.errorList.resendInvitationToNewEmail = [];
    if (localStorage.getItem("current_google_user_error")) {
      state.ui.error.errorList.resendInvitationToNewEmail.push(
        localStorage.getItem("current_google_user_error")
      );
    }
    function handleValidationResult(isValid: any) {
      state.data.validInput = isValid;
      // if (isValid) {
      //   console.log("Valid input received.");
      // } else {
      //   state.data.validInput = isValid;
      //   console.log("Invalid input.");
      // }
    }
    const resendInvitationError = computed(() => {
      if (localStorage.getItem("current_google_user_error")) {
        // const emailUsed = .split("#/").pop()
        return localStorage.getItem("current_google_user_error");
      } else {
        return state.data.resendInvitationError;
      }
    });
    // state.userName ? state.userName : localStorage.getItem(
    //     "current_google_user_image"
    //   );
    const {
      query: { returnUrl },
    } = useRoute();
    function processRegistration() {
      const payLoad = {
        uuid: route.query.uuid,
      };
      state.ui.postLoading.processRegistration = true;
      ethitransService
        .validateRegistration(payLoad)
        .then((res: any) => {
          state.registrationVerified = true;
          //  router.push({
          //   name: "admin-dashboard",
          // });
          // console.log("processRegistration Check:>>> ", res.data.data.projects);
        })
        .catch((error: any) => {
          //     state.errorList = [];
          // state.isErrorStatus = false;
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            // console.log("CHECK for Error >>>", res)
            if (res) {
              if (res.uuid) {
                state.errorList.push(res.uuid[0]);
                // state.errorList = [...state.errorList]
              } else {
                state.errorList.push(res);
              }
            }
          });
        })
        .finally(() => {
          state.ui.postLoading.processRegistration = false;
        });
    }
    function reloadPage() {
      // The last "domLoading" Time //
      // eslint-disable-next-line no-var
      var currentDocumentTimestamp = new Date(
        performance.timing.domLoading
      ).getTime();
      // Current Time //
      // eslint-disable-next-line no-var
      var now = Date.now();
      // Ten Seconds //
      // eslint-disable-next-line no-var
      var tenSec = 10 * 1000;
      // Plus Ten Seconds //
      // eslint-disable-next-line no-var
      var plusTenSec = currentDocumentTimestamp + tenSec;
      if (now > plusTenSec) {
        location.reload();
        // eslint-disable-next-line no-empty
      } else {
      }
    }

    //   FB.login(function(response: any){
    // // handle the response
    //   });

    function redirectToSignUp() {
      if (route.query.uuid && route.query.type === "invitation") {
        router.push({
          name: "admin-sign-up",
          query: {
            type: route.query.type,
            uuid: route.query.uuid,
            email: route.query.email,
          },
        });
      } else {
        router.push({
          name: "admin-sign-up",
        });
      }
    }
    function redirectToForgetPassword() {
      router.push({
        name: "admin-forget-password",
      });
    }
    function validateForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!validationService.isValidEmail(state.userName)) {
        state.errorList.push("Email address is required.");
      }
      if (!state.userPassword) {
        state.errorList.push("Password is required.");
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }
    function showreSendNewInvitation() {
      $("#resendNewInvitation").modal("show");
    }
    function hidereSendNewInvitation() {
      $("#resendNewInvitation").modal("hide");
      state.data.isNormalLogin = false;
    }
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function resendInvitationToNewEmail() {
      state.ui.error.errorList.resendInvitationToNewEmail = [];
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_id: route.query.uuid,
        email: state.data.isNormalLogin
          ? state.userName
          : localStorage.getItem("current_google_user_image"),
      };
      state.ui.postLoading.resendInvitationUuid = true;
      ethitransService
        .replaceInvitation(payLoad)
        .then((res: any) => {
          // console.log("resendInvitationToNewEmail Check:>>> ", res.data.data);
          hidereSendNewInvitation();
          state.errorList = [];
          state.isErrorStatus = false;
          localStorage.clear();
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage =
            "Invitation Resend Successfully. Please login with new Email.";
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(
              error,
              state.ui.error.errorList.resendInvitationToNewEmail
            )
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.resendInvitationToNewEmail.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.resendInvitationUuid = false;
        });
    }
    function logIn() {
      state.data.isNormalLogin = true;
      state.ui.error.errorList.resendInvitationToNewEmail = [];
      state.ui.error.hasError = false;
      validateForm();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      let payLoad = {} as any;
      if (route.query.uuid && route.query.type === "invitation") {
        payLoad = {
          email: common.lowerCase(state.userName),
          password: state.userPassword,
          uuid: route.query.uuid,
        };
      } else {
        payLoad = {
          email: common.lowerCase(state.userName),
          password: state.userPassword,
        };
      }
      // const payLoad = {
      //   email: state.userName,
      //   password: state.userPassword,
      //   uuid: route.query.uuid,
      // };
      state.postLoading = true;
      // console.log("CHECK the Payload:>>", payLoad);
      authService
        .logIn(payLoad)
        .then((res: any) => {
          cookie.setCookie('theme', 'dark')
          cookie.removeCookie('hover-time')
          console.log("Login Response Check:>>> ", res.data.data);
          // console.log(
          //   "INVITATION PENDING LIST:>>> ",
          //   res.data.data.pending_invitations
          // );
          if (Array.isArray(res.data.data.pending_invitations) === true) {
            // console.log(
            //   "INVITATION Type :>>> ",
            //   Array.isArray(res.data.data.pending_invitations)
            // );
            // console.log(
            //   "INVITATION Type :>>> ",
            //   res.data.data.pending_invitations.length
            // );
          }
          // console.log("Token Check:>>> ", res.data.data.token);
          // this.$router.push({ path: 'home' })
          localStorage.setItem("current_user_details", res.data.data.full_name);
          localStorage.setItem("current_user_email", res.data.data.email);
          localStorage.setItem("current_user_id", res.data.data.id);
          localStorage.setItem("current_user_uuid", res.data.data.uuid);
          localStorage.setItem("current_user_image", res.data.data.image);
          localStorage.setItem("current_user_token", res.data.data.token);
          localStorage.setItem("current_user_role", res.data.data.user_role);
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          // console.log("CHECK", state.data.rememberPassword);

          if (state.data.rememberPassword) {
            localStorage.setItem(
              "current_user_rememberPassword",
              `${state.data.rememberPassword}`
            );
            localStorage.setItem("current_user_username", state.userName);
            localStorage.setItem("current_user_password", state.userPassword);
          } else {
            localStorage.removeItem("current_user_username");
            localStorage.removeItem("current_user_password");
            localStorage.removeItem("current_user_rememberPassword");
          }
          state.userName = "";
          state.userPassword = "";
          // console.log("User", localStorage.getItem("current_user_username"));
          // console.log("PW", localStorage.getItem("current_user_password"));

          // router.back()
          // if(router.back()){

          // }
          if (returnUrl) {
            if (Array.isArray(res.data.data.pending_invitations) === true) {
              if (res.data.data.pending_invitations.length > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/" +
                  "#/" +
                  returnUrl;
                // console.log("NEW URL", newURL);
                window.location.replace(newURL);
              }
            } else {
              if (res.data.data.pending_invitations > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/" +
                  "#/" +
                  returnUrl;
                // console.log("NEW URL", newURL);
                window.location.replace(newURL);
              }
            }
          } else {
            // console.log("CHECK HERE FOR MORE >>>", res.data.data)
            if (Array.isArray(res.data.data.pending_invitations) === true) {
              if (res.data.data.pending_invitations.length > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                if (res.data.data.user_role === "admin") {
                  router.push({
                    name: "admin-verify-digitize-library",
                  });
                } else {
                  router.push({
                    name: "admin-dashboard",
                  });
                }
              }
            } else {
              if (res.data.data.pending_invitations > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                if (res.data.data.user_role === "admin") {
                  router.push({
                    name: "admin-verify-digitize-library",
                  });
                } else {
                  router.push({
                    name: "admin-dashboard",
                  });
                }
              }
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          if (
            error.response.status === 406 &&
            state.userName &&
            state.data.showReSendInvitation
          ) {
            localStorage.setItem(
              "current_google_user_error",
              error.response.data.message.error[0]
            );
            showreSendNewInvitation();
            common
              .sdCatchErr(
                error,
                state.ui.error.errorList.resendInvitationToNewEmail
              )
              .then((res: any) => {
                state.ui.error.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.ui.error.errorList.resendInvitationToNewEmail.push(
                      value[0]
                    );
                  }
                }
              });
          }
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;

            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
                if (value[0] === "User is not verified yet.") {
                  state.data.showResenVerificationMail = true;
                }
              }
            }
          });
        })

        // .catch((error: any) => {
        //   if (
        //     error.response.status === 406 &&
        //     state.userName &&
        //     state.data.showReSendInvitation
        //   ) {
        //     localStorage.setItem("current_google_user_error", error.response.data.message.error[0]);
        //     showreSendNewInvitation();
        //     // console.log("ERROR >>> ", error.response.)
        //   }
        //   common.sdCatchErr(error, state.errorList).then((res: any) => {
        //     state.isErrorStatus = true;
        //     for (const [key, value] of Object.entries(res)) {
        //       if (Array.isArray(value)) {
        //         state.errorList.push(value[0]);
        //       }
        //     }
        //   });
        // })
        .finally(() => {
          state.postLoading = false;
        });
    }

    function tokenLogin(token: any, email: any) {
      state.ui.error.errorList.loginToAccount = [];
      state.ui.error.hasError = false;
      if (state.ui.error.errorList.loginToAccount.length && state.ui.error.errorList.loginToAccount.length) return false;
      let payLoad = {} as any;
      payLoad = {
        token: token,
        email: email,
      };
      state.ui.postLoading.tokenLogin = true;
      // state.postLoading = true;
      authService
        .tokenLogin(payLoad)
        .then((res: any) => {
          state.ui.postLoading.tokenLogin = false;
          localStorage.setItem("current_user_details", res.data.data.full_name);
          localStorage.setItem("current_user_email", res.data.data.email);
          localStorage.setItem("current_user_id", res.data.data.id);
          localStorage.setItem("current_user_image", res.data.data.image);
          localStorage.setItem("current_user_token", res.data.data.token);
          localStorage.setItem("current_user_role", res.data.data.user_role);
          if(res.data.data.lang != undefined){
            localStorage.setItem("language",res.data.data.lang);
            store.dispatch("saveLanguage",res.data.data.lang);
          }
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          if (localStorage.getItem('current_user_token') && (res.data.data.redirect != undefined)) {
            if (res.data.data.redirect.type == "channel") {
              router.push({
                name: "admin-channel",
                params: { channelId: res.data.data.redirect.id },
              });
            } else if (res.data.data.redirect.type == "estimation") {
              router.push({
                name: "invite-job-estimation-details",
                params: { estimationId: res.data.data.redirect.id },
              });
            } else if (res.data.data.redirect.type == "smartcontract") {
              router.push({
                name: "user-project-acknowledgement",
                params: { invitationId: res.data.data.redirect.id },
              });
            }else if (res.data.data.redirect.type == "phase-invitation") {
              router.push({
                name: "user-phase-task-invitation-acknowledgement",
                params: { invitationId: res.data.data.redirect.id },
              });
            }

          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          common.sdCatchErr(error, state.ui.error.errorList.loginToAccount).then((res: any) => {
            state.ui.error.hasError = true;

            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.ui.error.errorList.loginToAccount.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          //  if (state.data.ChannelId) {
          //   router.push({
          //     name: "admin-channel",
          //     params: { channelId: state.data.ChannelId },
          //   });
          // }
          state.postLoading = false;
          // state.ui.postLoading.tokenLogin = false;
        });


    }

    function publicLogin() {
      state.data.isNormalLogin = true;
      state.ui.error.errorList.resendInvitationToNewEmail = [];
      state.ui.error.hasError = false;
      common.logOut();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      let payLoad = {} as any;
      payLoad = {
        token: state.token,
        channelId: state.data.ChannelId,
      };
      state.postLoading = true;
      authService
        .logInPublicUser(payLoad)
        .then((res: any) => {

          console.log("CHECK", res.data);
          localStorage.setItem("current_user_details", res.data.data.full_name);
          localStorage.setItem("current_user_email", res.data.data.email);
          localStorage.setItem("current_user_id", res.data.data.id);
          localStorage.setItem("current_user_image", res.data.data.image);
          localStorage.setItem("current_user_token", res.data.data.token);
          localStorage.setItem("current_user_role", res.data.data.user_role);
          localStorage.setItem("access_id", 'channel/' + state.data.ChannelId);
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          // console.log("CHECK", state.data.rememberPassword);
          if (localStorage.getItem('current_user_token')) {
            router.push({
              name: "admin-channel",
              params: { channelId: state.data.ChannelId },
            });
          }

          // }
          // state.userName = "";
          // state.userPassword = "";
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          console.log(error);
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            // console.log("CHECK for Error >>>", res)
            if (res) {
              // if (res.uuid) {
              //   state.errorList.push(res.uuid[0]);
              //   // state.errorList = [...state.errorList]
              // } else {
              //   state.errorList.push(res.error);
              // }
              state.errorList.push("You are no more public user");
            }
          });
        })
        .finally(() => {
          //  if (state.data.ChannelId) {
          //   router.push({
          //     name: "admin-channel",
          //     params: { channelId: state.data.ChannelId },
          //   });
          // }
          state.postLoading = false;
        });
    }
    function resendMail() {
      state.ui.error.errorList.loginToAccount = [];
      state.ui.error.hasError = false;
      if (state.ui.error.errorList.loginToAccount.length && state.ui.error.errorList.loginToAccount.length) return false;

      // if (state.errorList.length != 0 || state.isErrorStatus) return false;
      let payLoad = {} as any;
      payLoad = {
        email: state.userName ? state.userName : state.passCode.email,
      };
      state.postLoading = true;
      // console.log("CHECK the Payload:>>", payLoad);
      authService
        .resendMail(payLoad)
        .then((res: any) => {
          state.postLoading = false;
          // console.log("CHECK the Payload:>>", payLoad);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          common.sdCatchErr(error, state.ui.error.errorList.loginToAccount).then((res: any) => {
            state.ui.error.hasError = true;

            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.ui.error.errorList.loginToAccount.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          (state.userName = ""),
            (state.userPassword = ""),
            (state.data.successFulMessage =
              "Verification Mail Resend successfully please check your email");
          state.data.showSuccesModal = true;
          state.postLoading = false;
          state.data.showResenVerificationMail = false;
        });
    }

    function countDownTimer() {

      const interval = setInterval(() => {
        if (state.passCode.timeCount === 0) {
          // state.ui.sendOtpButton = true;
          // state.ui.inputOtp = false;
          clearInterval(interval);
          state.passCode.timeCount = 200;

          // state.data.phone.code = state.data.phone.code;
          // getCountryCodeList();
        } else {
          state.passCode.timeCount--;
        }
      }, 1000);
    }

    function validateOtp() {
      state.passCode.error = [];
      if (!validationService.isValidEmail(state.passCode.email)) {
        state.passCode.error.push("Email address is required.");
      }
    }
    function validateOtpLogin() {
      state.passCode.error = [];
      if (!state.passCode.otp) {
        state.errorList.push("Passcode is required.");
      }
    }
    function sendOtp() {
      state.passCode.showButton = false;
      validateOtp()
      if (state.passCode.error.length != 0) return false;
      let payLoad = {} as any;
      payLoad = {
        email: state.passCode.email,
      };
      authService
        .sendOtp(payLoad)
        .then((res: any) => {
          state.passCode.isSendOtp = true;
          state.passCode.resEmail = res.data.data.email;
          state.passCode.resMobile = res.data.data.mobile;
          state.passCode.successMessage = res.data.message;
          setTimeout(() => {
            state.passCode.successMessage = "";
          }, 9000);
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {

            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.passCode.error.push(value[0]);
                if (value[0] == 'User is not verified yet.') {
                  state.passCode.showButton = true;
                }
              }
            }
          });
        })
        .finally(() => {
          // state.passCode.error =[];
        });
    }
    function sendTokenLinks() {
      let payLoad = {} as any;
      payLoad = {
        email: state.invitationLinks.email,
      };
      console.log(payLoad);

      authService
        .sendTokenLinks(payLoad)
        .then((res: any) => {
          state.invitationLinks.isSent = true;
          // state.passCode.resEmail = res.data.data.email;
          // state.passCode.resMobile = res.data.data.mobile;
          state.invitationLinks.successMessage = res.data.message;
          setTimeout(() => {
            state.invitationLinks.successMessage = "";
          }, 9000);
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {

            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.passCode.error.push(value[0]);
                if (value[0] == 'User is not verified yet.') {
                  state.passCode.showButton = true;
                }
              }
            }
          });
        })
        .finally(() => {
          // state.passCode.error =[];
        });
    }


    function logInWithOtp() {
      // state.data.isNormalLogin = true;
      state.ui.error.errorList.resendInvitationToNewEmail = [];
      state.ui.error.hasError = false;
      if (state.passCode.error.length != 0 || state.isErrorStatus) return false;
      let payLoad = {} as any;
      if (route.query.uuid && route.query.type === "invitation") {
        payLoad = {
          email: common.lowerCase(state.passCode.email),
          otp: state.passCode.otp,
          uuid: route.query.uuid,
        };
      } else {
        payLoad = {
          email: common.lowerCase(state.passCode.email),
          otp: state.passCode.otp,
        };
      }
      state.postLoading = true;
      authService
        .logInWithOtp(payLoad)
        .then((res: any) => {
          localStorage.setItem("current_user_details", res.data.data.full_name);
          localStorage.setItem("current_user_email", res.data.data.email);
          localStorage.setItem("current_user_id", res.data.data.id);
          localStorage.setItem("current_user_uuid", res.data.data.uuid);
          localStorage.setItem("current_user_image", res.data.data.image);
          localStorage.setItem("current_user_token", res.data.data.token);
          localStorage.setItem("current_user_role", res.data.data.user_role);
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          if (state.data.rememberPassword) {
            localStorage.setItem(
              "current_user_rememberPassword",
              `${state.data.rememberPassword}`
            );
            localStorage.setItem("current_user_username", state.userName);
            localStorage.setItem("current_user_password", state.userPassword);
          } else {
            localStorage.removeItem("current_user_username");
            localStorage.removeItem("current_user_password");
            localStorage.removeItem("current_user_rememberPassword");
          }
          state.userName = "";
          state.userPassword = "";
          if (returnUrl) {
            if (Array.isArray(res.data.data.pending_invitations) === true) {
              if (res.data.data.pending_invitations.length > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/" +
                  "#/" +
                  returnUrl;
                // console.log("NEW URL", newURL);
                window.location.replace(newURL);
              }
            } else {
              if (res.data.data.pending_invitations > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/" +
                  "#/" +
                  returnUrl;
                // console.log("NEW URL", newURL);
                window.location.replace(newURL);
              }
            }
          } else {
            // console.log("CHECK HERE FOR MORE >>>", res.data.data)
            if (Array.isArray(res.data.data.pending_invitations) === true) {
              if (res.data.data.pending_invitations.length > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                if (res.data.data.user_role === "admin") {
                  router.push({
                    name: "admin-verify-digitize-library",
                  });
                } else {
                  router.push({
                    name: "admin-dashboard",
                  });
                }
              }
            } else {
              if (res.data.data.pending_invitations > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                if (res.data.data.user_role === "admin") {
                  router.push({
                    name: "admin-verify-digitize-library",
                  });
                } else {
                  router.push({
                    name: "admin-dashboard",
                  });
                }
              }
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          state.passCode.errorLogin.push(error.response.data.message.error[0]);
          // common.sdCatchErr(error,  state.passCode.errorLogin).then((res: any) => {
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.passCode.errorLogin.push(value[0]);
          //     }
          //   }
          // });
        })
        .finally(() => {
          state.postLoading = false;
        });
    }

    function checkOtp() {
      if (state.passCode.otp.length >= 6) {
        state.passCode.errorLogin = [];
        logInWithOtp();
      }
    }
    function showPassword() {
      state.data.isPasswordShow = !state.data.isPasswordShow;
    }
    function dataClear() {
      state.ui.showUI.changeInput = false;
      state.ui.disabled.primary = false;
      state.passCode.email = "",
        state.passCode.otp = "",
        state.passCode.error = []
      state.passCode.errorLogin = []
      state.passCode.resEmail = "",
        state.passCode.resMobile = "",
        state.passCode.timeCount = 200,
        state.passCode.isSendOtp = false
      state.errorList = [],
        state.isErrorStatus = false
      state.passCode.showButton = false;
      state.invitationLinks.show = false;
      state.ui.showUI.counter = false;
      state.ui.error.errorList.loginToAccount = [];
      state.ui.error.hasError = false;

    }
    function showGetInviationLink() {
      state.passCode.showButton = true;
      state.invitationLinks.show = true;
      state.invitationLinks.isSent = false;
    }

    function showLoader() {
      const container = document.getElementById('f1_container');
      const loader = document.getElementById('loader');
      if (container) {
        container.style.display = "none"
      }
      if (loader) {
        loader.style.display = "block";
      }
    }
    const formatMobileNumber = (number: any) => {
      return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    };
    const validateInput = () => {
      // let errorMessage = '';
      // const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const emailPattern = /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/;
      const mobilePattern = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;


      if (emailPattern.test(enteredValue.value)) {
        // emit('validation-result', true); // Emit true if valid email
      } else if (/^\d{10}$/.test(enteredValue.value)) {
        enteredValue.value = formatMobileNumber(enteredValue.value);
        // emit('validation-result', true); // Emit true if valid unformatted mobile
      }
      else if (mobilePattern.test(enteredValue.value)) {
        // emit('validation-result', true); // Emit true if valid formatted mobile
      }
      else {
        // errorMessage = 'Invalid input. Please enter a valid email or a mobile number with exactly 10 digits.';
        state.ui.error.errorList.loginToAccount.push("Invalid input. Please enter a valid email or a mobile number with exactly 10 digits.");
        // emit('emit-error', errorMessage);
        // emit('validation-result', false); // Emit false if invalid
      }
    };
    function validateLogin() {
      state.ui.error.errorList.loginToAccount = [];
      state.ui.error.hasError = false;

      if (!enteredValue.value) {
        state.ui.error.errorList.loginToAccount.push("Email/Mobile Number is required.");
      } else {
        validateInput();
      }

      if (
        !state.ui.error.errorList.loginToAccount &&
        !state.ui.error.errorList.loginToAccount.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.loginToAccount.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function checkOtpMessage(array: any) {
      return array[0] === "The OTP has already been sent.";
    }
    function keepFirstItemOnly(array: any) {
      if (array.length > 1) {
        // Keep only the first item at index 0 and remove the rest
        array.splice(1);
      }
      return array;
    }

    function getOTPForLogin() {
      state.ui.error.errorList.loginToAccount = [];
      state.ui.error.hasError = false;
      state.ui.showUI.counter = false;
      validateLogin();
      if (state.ui.error.errorList.loginToAccount.length && state.ui.error.errorList.loginToAccount.length) return false;
      if (state.ui.postLoading.getOTPForLogin) return false;
      const payLoad = {
        client: common.filterPhoneNumber(enteredValue.value),
      };
      if (validationService.isValidEmail(enteredValue.value)) {
        payLoad.client = enteredValue.value;
      } else {
        payLoad.client = common.filterPhoneNumber(enteredValue.value)
      }
      state.ui.postLoading.getOTPForLogin = true;
      ethitransService
        .sentOtpForLogin(payLoad)
        .then((res: any) => {
          state.ui.showUI.changeInput = true;
          state.ui.showUI.counter = true;
          state.ui.showUI.resendOTP = false
          state.ui.disabled.primary = true;
          console.log("CHECK RES >>> ", res.data.data)
          state.ui.postLoading.getOTPForLogin = false;
          state.ui.showUI.otp = true;
          // state.passCode.shortReference = enteredValue.value;
          state.passCode.shortReference = common.maskStringWithAmpersand(enteredValue.value)
          state.passCode.resEmail = res.data.data.email;
          state.passCode.resMobile = res.data.data.mobile;
          state.passCode.successMessage = "Pass Code send successfuly.";
          setTimeout(() => {
            state.passCode.successMessage = "";
          }, 9000);
        })
        .catch((error: any) => {
          console.log("REPONSE CHECK >>> ", error.status)
          state.passCode.shortReference = common.maskStringWithAmpersand(enteredValue.value)
          common
            .sdCatchErr(error, state.ui.error.errorList.loginToAccount)
            .then((res: any) => {
              console.log("error", res

              )
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.loginToAccount.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.getOTPForLogin = false;
          // alert("ERROR");
          console.log("CHEK 123 ", checkOtpMessage(state.ui.error.errorList.loginToAccount))
          if (checkOtpMessage(state.ui.error.errorList.loginToAccount)) {
            state.ui.showUI.counter = true;
            state.ui.disabled.primary = true;
            state.ui.showUI.changeInput = true;
            state.data.setCounter = state.ui.error.errorList.loginToAccount[1];
            keepFirstItemOnly(state.ui.error.errorList.loginToAccount);

          }
        });
    }
    function checkOtpToLogin(otp: number) {
      console.log("CHECK OTP")
      state.ui.error.errorList.loginToAccount = [];
      state.ui.error.hasError = false;

      if (state.ui.postLoading.checkOtpToLogin) return false;

      const payLoad = {
        client: "",
        otp: otp
      };
      if (validationService.isValidEmail(enteredValue.value)) {
        payLoad.client = enteredValue.value;
      } else {
        payLoad.client = common.filterPhoneNumber(enteredValue.value)
      }
      state.ui.postLoading.checkOtpToLogin = true;

      console.log("Checkpayload >>> ", payLoad)
      ethitransService
        .verifyOtpCodeForLogin(payLoad)
        .then((res: any) => {
          state.ui.postLoading.checkOtpToLogin = false;
          localStorage.setItem("current_user_details", res.data.data.full_name);
          localStorage.setItem("current_user_email", res.data.data.email);
          localStorage.setItem("current_user_id", res.data.data.id);
          localStorage.setItem("current_user_uuid", res.data.data.uuid);
          localStorage.setItem("current_user_image", res.data.data.image);
          localStorage.setItem("current_user_token", res.data.data.token);
          localStorage.setItem("current_user_role", res.data.data.user_role);
          initializeSocket(res.data.data.token);
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          if (state.data.rememberPassword) {
            localStorage.setItem(
              "current_user_rememberPassword",
              `${state.data.rememberPassword}`
            );
            localStorage.setItem("current_user_username", state.userName);
            localStorage.setItem("current_user_password", state.userPassword);
          } else {
            localStorage.removeItem("current_user_username");
            localStorage.removeItem("current_user_password");
            localStorage.removeItem("current_user_rememberPassword");
          }
          state.userName = "";
          state.userPassword = "";
          console.log("<<< SUCCESS IN >>>")
          if (returnUrl) {
            if (Array.isArray(res.data.data.pending_invitations) === true) {
              console.log("SUCCESS >>> INVITATION TRUE")
              if (res.data.data.pending_invitations.length > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/" +
                  "#/" +
                  returnUrl;
                // console.log("NEW URL", newURL);
                window.location.replace(newURL);
              }
            } else {
              console.log("SUCCESS >>> INVITATION TRUE")
              if (res.data.data.pending_invitations > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/" +
                  "#/" +
                  returnUrl;
                // console.log("NEW URL", newURL);
                window.location.replace(newURL);
              }
            }
          } else {
            console.log("SUCCESS >>> TWO TRUE")
            // console.log("CHECK HERE FOR MORE >>>", res.data.data)
            if (Array.isArray(res.data.data.pending_invitations) === true) {
              if (res.data.data.pending_invitations.length > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                if (res.data.data.user_role === "admin") {
                  router.push({
                    name: "admin-verify-digitize-library",
                  });
                } else {
                  router.push({
                    name: "admin-dashboard",
                  });
                }
              }
            } else {
              if (res.data.data.pending_invitations > 0) {
                router.push({
                  name: "admin-invitation",
                });
              } else {
                if (res.data.data.user_role === "admin") {
                  router.push({
                    name: "admin-verify-digitize-library",
                  });
                } else {
                  router.push({
                    name: "admin-dashboard",
                  });
                }
              }
            }
          }
        })
        .catch((error: any) => {
          console.log("HI", error)
          common
            .sdCatchErr(error, state.ui.error.errorList.loginToAccount)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.loginToAccount.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.checkOtpToLogin = false;
        });
    }
    const handleSixDigitOTP = (otp: number) => {
      // console.log("CHECK THE OTP >>> ", otp)
      checkOtpToLogin(otp);
    }
    function registrationSuccess() {
      // setActiveTab('login');
      window.location.reload()
    }
    onMounted(() => {
      state.imageBaseUrl = config.imageBaseUrl;
      const theme = cookie.getCookie('theme');
      if (theme) {
        state.data.title = "Welcome Back";
      }
      console.log("HI HELLO", theme); // Outputs the value of the 'theme' cookie
      //  signOut();
      state.data.imageBaseUrl = config.imageBaseUrl;
      // reloadPage();
      localStorage.setItem("current_user_uuid", JSON.stringify(uuid));
      if (route.query.uuid && route.query.type === "registration") {
        processRegistration();
      } else if (route.query.uuid && route.query.type === "invitation") {
        // console.log("INVITATION AND UUID >>> ", route.query.uuid);
        showLoader();
        state.data.showReSendInvitation = true;
        state.userName = route.query.email;
        tokenLogin(route.query.uuid, route.query.email);
      }else if (route.query.id && route.query.type === "phaseinvitation") {
        showLoader();
        state.data.showReSendInvitation = true;
        state.userName = route.query.email;
        tokenLogin(route.query.id, route.query.email);
      } else if (route.query.type === "public_user") {
        state.token = route.query.token,
          state.data.ChannelId = route.query.uuid;
        publicLogin();
      } else if (route.query.returnUrl != undefined) {
        if (route.query.returnUrl.includes('job/estimation/invitaion/details')) {
          let token = (route.query.returnUrl as string).replace('job/estimation/invitaion/details', '');
          let email = '';
          if (token.indexOf('?email') > 0) {
            email = token.substr(token.indexOf('?email') + 7);
            token = token.substr(0, token.indexOf('?email'));

          }
          showLoader();
          tokenLogin(token, email);
        } else if (route.query.returnUrl.includes('project-acknowledgement-details')) {
          let token = (route.query.returnUrl as string).replace('project-acknowledgement-details/', '');
          let email = '';
          if (token.indexOf('?email') > 0) {
            email = token.substr(token.indexOf('?email') + 7);
            token = token.substr(0, token.indexOf('?email'));

          }
          showLoader();
          tokenLogin(token, email);
        }
      }
      const rememberPassword = localStorage.getItem(
        "current_user_rememberPassword"
      );
      if (rememberPassword === "true") {
        state.data.rememberPassword = true;
        // console.log("Remember is Set >>> ", state.data.rememberPassword);
      }
      if (state.data.rememberPassword) {
        const upw: any = localStorage.getItem("current_user_password")
        state.userName = localStorage.getItem("current_user_username");
        state.userPassword = common.lowerCase(upw);
        // state.userPassword = localStorage.getItem("current_user_rememberPassword");
        // logIn()
      }

    });
    const handleInput = (value: any) => {
      enteredValue.value = value; // Update the entered value from child
      errorMessage.value = ''; // Clear error if input is valid
      if (validationService.isValidEmail(enteredValue.value)) {
        state.data.isPrimaryEmail = true;
      } else {
        state.data.isPrimaryEmail = false;
      }
    };
    const handleStatusUpdate = (value: any) => {
      if (value === true) {
        state.ui.showUI.resendOTP = true;
      }
    }

    return {
      state,
      formatMobileNumber,
      validateInput,
      buttonhasValidInput,
      handleValidationResult,
      keepFirstItemOnly,
      checkOtpMessage,
      handleStatusUpdate,
      handleSixDigitOTP,
      clearInputValue,
      handleInput,
      handleError,
      handleClear,
      enteredValue,
      errorMessage,
      registrationSuccess,
      checkOtpToLogin,
      validateLogin,
      getOTPForLogin,
      setActiveTab,
      userError,
      resendInvitationError,
      userName,
      logIn,
      closeSuccessModal,
      showreSendNewInvitation,
      hidereSendNewInvitation,
      resendInvitationToNewEmail,
      redirectToForgetPassword,
      processRegistration,
      redirectToSignUp,
      validateForm,
      resendMail,
      publicLogin,
      showPassword,
      sendOtp,
      countDownTimer,
      logInWithOtp,
      checkOtp,
      dataClear,
      validateOtp,
      validateOtpLogin,
      showGetInviationLink,
      sendTokenLinks,
      showLoader
    };
  },
});
