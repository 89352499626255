
import { defineComponent, reactive, onMounted, computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ProjectImageUploadModal from "@/components/modal/ProjectImageUploadModal.vue";

// import MembersCard from "@/components/cards/MembersCard.vue";
import TemplateCard from "@/components/cards/TemplateCard.vue";
// import ChannelCard from "@/components/cards/ChannelCard.vue";
// import ChannelCardTest from "@/components/cards/ChannelCardTest.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import ProjectApprovalModal from "@/components/projectDetail/ProjectApprovalModal.vue";
import ProjectApprovalModalEdit from "@/components/projectDetail/ProjectApprovalModalEdit.vue";
import ToDoDetailsAttachment from "@/components/todo/ToDoDetailsAttachment.vue";
import GoogleMapView from "@/components/commonDesign/GoogleMapView.vue";

import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'

import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Pusher from "pusher-js";
import { useStore } from "vuex";
import introJs from "intro.js";
import state from "pusher-js/types/src/core/http/state";
// import AppTour from "@/components/modal/AppTour.vue";
import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css';
import Multiselect from 'vue-multiselect';
import { VueDraggableNext } from 'vue-draggable-next'
import { useCookie } from 'vue-cookie-next'

import AppPhoneNumberInput from "@/components/commonDesign/AppPhoneNumberInput.vue"

export default defineComponent({
  name: "ProjectDetails",
  props: {
    msg: String,
  },
  components: {
  },
  setup() {


    return {
    };
  },
});
