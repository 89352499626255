
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import Multiselect from 'vue-multiselect';
import AppPhoneNumberInput from "@/components/commonDesign/AppPhoneNumberInput.vue"


import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
export default defineComponent({
  name: "ChatChannelAppInviteMember",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    pendingList:{
      type: Array,
      required: false,
    },
    projectUUID: {
      type: String,
      required: true,
    },
    channelUUID: {
      type: String,
      required: true,
    }
  },
  components: {
    Multiselect,
    ErrorList,
    AppPhoneNumberInput,
  },
  setup(props, { emit }) {
    const allChannels = ref([]) as any;
    const allUsers = ref([]) as any;
    const selectedUsers = ref([]) as any;
    const selectedValue = ref(2) as any;
    const selectedDateForMember = ref('') as any;
    const state = reactive({
      channelList: [] as any,
      invitationMessage: "",
      sendChannelId: [] as any,
      inviteAsPublic: [] as any,
      userList: [] as any,
      showCheckChannelMessage: false,
      selectedAllChannel: [] as any,
      invite: {
        users: [] as any[],
        invites: [] as any[],
        filteredUsers: [] as any[],
        filteredInvites: [] as any[],
      },
      inviteMember: "",
      data: {
        search: "",
        channelStatus: false as any,
        invitationVerifyMsg: "",
        invitationVerify: {
          showStatus: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
        showCheckChannelMessage: false,
        channelList: [] as any,
        inviteAsPublicUser: false,
        inviteAsGuest: false,
        selectedChannelByProject: {
          channel: {
            uuid: "",
          }
        }
      },
      ui: {
        isLoading: {
          getChannelByProject: false,
          getProjectChannel: false,
          searchProjectChannel: false,
        },
        isPostLoading: {
          inviteAllUsers: false,
          removeSelectedImage: false,
          acknowledgeRequest: false,
          projectMemberListByChannel: false,
          inviteAllPublicUser: false,
        },
        errors: {
          errorList: {
            errorList: [] as any,
          },
          hasError: false,
        },
      },
    });
    

    // Method to handle filtered objects
    function handleFilteredObjects(filteredObjects: any, selectedRow: any) {
      console.log('Filtered objects:', selectedRow);
      const userSelected: any = JSON.parse(JSON.stringify(selectedRow));
      console.log('Filtered objects:', filteredObjects);
      state.invite.users = state.invite.users.filter((e: any) => e.email !== userSelected.previousEmail && e.phone !== userSelected.previousPhone);
      if(!userSelected.phone){
        userSelected.phone =  userSelected.previousPhone
      }
      if(!userSelected.email){
        userSelected.email =  userSelected.previousEmail
      }


      const row: any = JSON.parse(JSON.stringify(common.filterAndTransformUsers(state.userList,userSelected.previousPhone,userSelected.previousEmail)))
      // eslint-disable-next-line @typescript-eslint/camelcase
      row[0].full_name = row[0].first_name + " " + row[0].last_name
      row[0].email = row[0].previousEmail;
      row[0].phone = row[0].previousPhone;


      const setRow: any = {};
      setRow.label = row[0].email
      setRow.email = row[0].email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = row[0].full_name;
      setRow.phone = row[0].phone
      console.log("Filtered Row >>> ", setRow);
      allUsers.value.push(setRow);
      // Add your logic here to handle these objects
      // alert("IN HERE and Activate Delete")
    }
    const checkEmptyFields = (index: any, key: any) => {
      if (state.invite.invites[index][key] === "") {
        if((!state.invite.invites[index].key && state.invite.invites[index].previousPhone) || (!state.invite.invites[index].key && state.invite.invites[index].previousEmail)){
          let itemForDelete: any = {};
          itemForDelete = state.invite.invites[index];
          handleFilteredObjects(`${key} Is not available`, itemForDelete);
        }
      } 
    };
    // eslint-disable-next-line @typescript-eslint/camelcase
    const nameWithEmail = ({ full_name, email }: any) => {
      // eslint-disable-next-line @typescript-eslint/camelcase
      return email ? `${full_name} — [${email}]` : '';
    };
    const hasValidLastContact = computed(() => {
      if (state.invite.invites.length === 0) {
        return false;
      }
      // state.invite.invites.forEach((member: any) => {
      //   // track the notify by is populated only once when information is populated
      //   member.mapNotifyByEmail = false;
      //   member.mapNotifyBySms = false;

      // })
      const members = state.invite.invites;

      const lastMember = members[members.length - 1];
      const hasValidEmail = lastMember?.email ? validationService.isValidEmail(lastMember.email) : false;
      const hasValidMobile = lastMember?.phone ? validationService.validateMobileNumber(lastMember.phone) : false;
      if (hasValidEmail) {
        lastMember.mailNotification = true;
        lastMember.mapNotifyByEmail = true;
      } else {
        lastMember.mailNotification = false;
      }
      if (hasValidMobile) {
        lastMember.smsNotification = true;
        lastMember.mapNotifyBySms = true;
      } else {
        lastMember.smsNotification = false;
      }


      // Return true if either the email or mobile number is valid
      return hasValidEmail || hasValidMobile;
    });
    function deletePublicMember() {
      // future delete public member here
    }
    function validationPublicUser() {
      state.ui.errors.hasError = false;
      state.ui.errors.errorList.errorList = [];


      if (!state.selectedAllChannel.length) {
        state.ui.errors.errorList.errorList.push("Channel is required.");
      }

      if (!state.inviteAsPublic && !state.inviteAsPublic.length) {
        state.ui.errors.errorList.errorList.push("At least 1 user is required.");
      } else {
        state.inviteAsPublic.forEach((item: any) => {
          if (item.email && !validationService.isValidEmail(item.email)) {
            state.ui.errors.errorList.errorList.push("Invalid email.");
          }
          if (!item.email) {
            state.ui.errors.errorList.errorList.push("Email must be required.");
          }
          //  if (!item.name) {
          //   state.ui.errors.errorList.errorList.push("Name must be required.");
          // }
          if (item.smsNotification) {
            if (!item.phone) {
              state.ui.errors.errorList.errorList.push("Mobile number must be required.");
              state.ui.errors.hasError = true;
            } else if (item.phone.length != 11) {
              state.ui.errors.errorList.errorList.push("Mobile Number must be of 10 digit.");
              state.ui.errors.hasError = true;
            }
          }
          //  if(!item.smsNotification && !item.emailNotification) {
          //   state.ui.errors.errorList.errorList.push("Email or SMS one method must be required.");
          // }
        });
      }
      if (!state.ui.errors.errorList.errorList && !state.ui.errors.errorList.errorList.length) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.errorList.length != 0) {
        state.ui.errors.hasError = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }
    const changeInvitePublicMember = () => {
      validationPublicUser();
      if (state.ui.errors.errorList.errorList.length != 0 || state.ui.errors.hasError) return false;
      state.inviteAsPublic.push({
        name: "",
        email: "",
        phone: "",
        smsNotification: false,
        mailNotification: true,
      });
    }

    const addNewMember = () => {
      if (state.ui.errors.errorList.errorList.length && state.ui.errors.hasError) {
        if (state.invite.invites && state.invite.invites.length) {
          state.invite.invites.forEach((item: any) => {
            if (item.first_name || item.last_name) {
              item.fNameError = false;
              item.lNameError = false;
              state.ui.errors.errorList.errorList = [];
              state.ui.errors.hasError = false;
            }

          })
        }
      }
      state.invite.invites.push({
        'email': "",
        'first_name': '',
        'last_name': '',
        'phone': '',
        'mailNotification': false,
        'smsNotification': false,
        'mailDisable': false,
      });
    }
    const removeSelectedMember = (item: any) => {
      // console.log("check the  HHHHsELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      state.invite.invites = state.invite.invites.filter((e: any) => e.email !== item.email && e.phone !== item.phone);

      // insert if the length gets to zero
      if (state.invite.invites && state.invite.invites.length === 0) {
        state.invite.invites.push({
          'email': "",
          'first_name': '',
          'last_name': '',
          'phone': '',
          'mailNotification': false,
          'smsNotification': false,
          'mailDisable': false,
        });
      }

      const setRow: any = {};
      setRow.label = item.email
      setRow.email = item.email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = item.full_name = item.first_name + " " + item.last_name;
      setRow.phone = item.phone
      // allUsers.value.push(item);
      allUsers.value.push(setRow);

    }
    const removeSelectedUser = (item: any) => {
      // console.log("REMOVE CHECK >>>> ", item)
      if (state.invite.invites.length === 1) return false;
      state.invite.invites = state.invite.invites.filter((e: any) => e != item);

      // reset the invite existing member
      state.invite.users = state.invite.users.filter((e: any) => e.email !== item.email && e.phone !== item.phone);
      const setRow: any = {};
      setRow.label = item.email
      setRow.email = item.email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = item.full_name = item.first_name + " " + item.last_name;
      setRow.phone = item.phone
      // allUsers.value.push(item);
      allUsers.value.push(setRow);
      // allUsers.value.push(item);
    }
    const fetchCustomerDetails = (email: string) => {
      state.ui.errors.hasError = false;
      state.ui.errors.errorList.errorList = [];

      ethitransService.detailsByemail(email)
        .then(response => {
          const selectedCustomer: any = response.data;
          if (selectedCustomer && selectedCustomer.data) {
            const data = selectedCustomer.data;

            const customerData: any = {
              email: data.email,
              phone: data.mobile_number || '',
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: data.first_name || '',
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: data.last_name || '',
              smsNotification: validationService.validateMobileNumber(data.mobile_number) ? true : false,
              mailNotification: validationService.isValidEmail(data.email) ? true : false,
              previousEmail: validationService.isValidEmail(data.email) ? data.email : null,
              previousPhone: data.mobile_number || '',
              // mapNotifyByEmail : true,
              // mapNotifyBySms: true,
            };

            // Find and update the existing user in the invite list
            const existingCustomerIndex = state.invite.users.findIndex(
              // eslint-disable-next-line @typescript-eslint/camelcase
              (customer: any) => customer.email === data.email || customer.mobile_number === data.mobile_number
            );
            if (existingCustomerIndex !== -1) {
              // Update the user details
              state.invite.invites[existingCustomerIndex] = { ...customerData };
            } else {
              // Add new customer if not found
              state.invite.invites.push(customerData);
            }
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
    }

    const selectToChangeUsers = (value: any) => {
      const existingIndex = state.invite.users.some((obj: any) => obj.id === value.id);
      if (value.id == "convo101" && allUsers.value.length >= 2) {
        const filteredArray = allUsers.value.filter((item: any) => item.id !== "convo101");
        state.invite.users = state.invite.users.concat(filteredArray);
        allUsers.value = [
          {
            'id': 'convo101',
            'label': 'Select All users',
            'email': 'Select All users',
            'full_name': 'Select All users',
          }
        ];
      }
      else if (!existingIndex && value.id != "convo101") {
        state.invite.users.push(value);
        // filtered the selected
        const filteredArray = allUsers.value.filter((item: any) => item.id !== value.id);
        allUsers.value = filteredArray;
        fetchCustomerDetails(value.email);
      }
      selectedUsers.value = {};
    }
    const showCreateProjectChannel = () => {
      // emit for create new channel
    }
    // async function getChannelByProject(channelStatus: any, projectUUID: any) {
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     project_id: props.projectUUID,
    //   };
    //   state.ui.isPostLoading.getChannelByProject = true;
    //   await ethitransService
    //     .getChannelListByProject(payLoad)
    //     .then((res: any) => {
    //       console.log("getChannelByProject Response Check:>>> ", res.data.data);
    //       allChannels.value = [
    //         {
    //           'id': 'convo101',
    //           'label': 'Select All Channels',
    //           'name': 'Select All Channels',
    //         }
    //       ];
    //       state.data.channelList = res.data.data ? res.data.data : [];
    //       res.data.data.forEach((channel: any) => {
    //         if (channel.type != 4) {
    //           allChannels.value.push({
    //             'id': channel.uuid,
    //             'label': channel.name,
    //             'name': channel.name,
    //           })
    //         }

    //       })
    //       if (cookie.getCookie('channelList')) {
    //         const channels = cookie.getCookie('channelList');
    //         // console.log("HI HELLO", channels);
    //         // console.log("retuired Object", getObjectById(allChannels.value, channels));
    //         selectToChange(getObjectById(allChannels.value, channels));
    //       }
    //       if (state.data.channelList && state.data.channelList.length) {
    //         if (!state.data.channelList && !state.data.channelList.length)
    //           return false;
    //         if (!channelStatus) {
    //           state.data.selectedChannelByProject.channel =
    //             state.data.channelList[state.data.channelList.length - 1];
    //           // console.log("IF THE CHANNEL SELECTED >>>", state.data.selectedChannelByProject.channel)
    //         }
    //         //  else {
    //         //   const data = state.data.channelList.filter(
    //         //     (e: any) =>
    //         //       e.uuid === state.data.selectedChannelByProject.channel.uuid
    //         //   );
    //         //   // state.data.selectedChannelByProject.channel = data[0];
    //         //   // console.log("ELSE CHANNEL DIRECT SELECT >>>", state.data.selectedChannelByProject.channel)
    //         // }
    //         projectMemberListByChannel();
    //         state.showCheckChannelMessage = true;
    //       } else {
    //         state.showCheckChannelMessage = false;
    //       }
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isPostLoading.getChannelByProject = false;
    //     });
    // }
    const hideChatChannelInviteMember = () => {
      state.selectedAllChannel = [];
      $("#chatChannelInviteMember").modal("hide");
      state.data.inviteAsPublicUser = false;
      $("#chatChannelInviteMember").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });

      state.data.invitationVerifyMsg = "";
      state.data.invitationVerify.newMemberList = [];
      state.data.invitationVerify.registeredMemberList = [];
      state.data.invitationVerify.pendingMemberList = [];

      emit("closeChatChannelInviteModal");
    }
    const projectMemberListByChannel = () => {
      state.ui.errors.hasError = false;
      state.ui.errors.errorList.errorList = [];

      if (state.ui.isPostLoading.projectMemberListByChannel) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: props.projectUUID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.selectedChannelByProject.channel.uuid ? state.data.selectedChannelByProject.channel.uuid : props.channelUUID,
      };
      state.ui.isPostLoading.projectMemberListByChannel = true;
      ethitransService
        .projectMemberListByChannel(payLoad)
        .then((res: any) => {
          console.log("<<< Prabhu Check:>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];

          allUsers.value = [
            {
              'id': 'convo101',
              'label': 'Select All user',
              'email': 'Select All user',
              'full_name': 'Select All user'
            }
          ];
          // filter the array
          // 
          // channel Member && pending list
          res.data.data.forEach((user: any) => {
            allUsers.value.push({
              'id': user.uuid,
              'label': user.email,
              'email': user.email,
              'full_name': user.full_name,
              'phone': user.mobile_number,
            })
          })
          // allUsers.value = common.addToPendingList(props.pendingList, allUsers.value);

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.projectMemberListByChannel = false;
        });
    }
    const getProjectChannel = () => {
      state.ui.errors.errorList.errorList = []
      state.ui.errors.hasError = false;

      state.ui.isLoading.getProjectChannel = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const project_id = props.projectUUID;
      ethitransService
        .getProjectChannel(project_id, state.data.search, state.data.channelStatus)
        .then((res: any) => {
          state.channelList = res.data.data.channels
            ? res.data.data.channels
            : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getProjectChannel = false;
          state.ui.isLoading.searchProjectChannel = false;
        });
    }
    const inviteAllPublicUser = () => {
      state.ui.errors.errorList.errorList = []
      state.ui.errors.hasError = false;

      state.sendChannelId = [];
      state.selectedAllChannel.forEach((item: any) => {
        state.sendChannelId.push(item.id);
      });
      validationPublicUser();
      if (state.ui.errors.errorList.errorList.length != 0 || state.ui.errors.hasError) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: props.projectUUID,
        channels: state.sendChannelId,
        users: common.removeDuplicate(state.invite.filteredUsers),
        invites: state.inviteAsPublic,
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.ui.isPostLoading.inviteAllUsers = true;
      ethitransService
        .invitationPublicUser(payLoad)
        .then((res: any) => {
          console.log("Users Invite Check:>>> ", res.data.message);
          hideChatChannelInviteMember();
          // trigger successful modal from project details or chat channel
          // showSuccessModal();
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.invitationMessage = res.data.message ? res.data.message : "";
          getProjectChannel();
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.inviteAllUsers = false;
          state.data.inviteAsPublicUser = false;
        });
    }


    const validateInviteForm = () => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;

      if (!state.invite.users.length && !state.invite.filteredInvites.length) {
        state.ui.errors.errorList.errorList.push("Invite member is required.");
      }
      if (!state.selectedAllChannel.length) {
        state.ui.errors.errorList.errorList.push("Channel is required.");
      }
      if (selectedValue.value === "4" && !selectedDateForMember.value) {
        state.ui.errors.errorList.errorList.push("Please select the date.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.ui.errors.errorList.errorList.push("Invalid email.");
        }
      }

      // if (state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '' || (item.smsNotification && (item.phone == '')))) {
      state.invite.invites.forEach((invite: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.first_name == '') {
          invite.fNameError = true;
          state.ui.errors.errorList.errorList.push("First Name must be filled out to continue.");
        } else {
          invite.fNameError = false;
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.last_name == '') {
          invite.lNameError = true;
          state.ui.errors.errorList.errorList.push("Last Name must be filled out to continue.");
        } else {
          invite.lNameError = false;
        }
        if (invite.smsNotification) {
          if (invite.phone == '') {
            invite.phoneError = true;
            state.ui.errors.errorList.errorList.push("Mobile Number must be filled out to continue.");
            //   state.ui.errors.errorList.errorList.push(${`'Mobile Number must be filled out to continue for '${invite.first_name}.`});
          }
          else if (invite.phone.length != 11) {
            invite.phoneError = true;
            state.ui.errors.errorList.errorList.push("Mobile Number must be of 10 digit.");
          } else {
            invite.phoneError = false;
          }
        }

      })

      // }

      if (!state.ui.errors.errorList.errorList && !state.ui.errors.errorList.errorList.length) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.errorList.length != 0) {
        state.ui.errors.hasError = true;
      }
    }

    const inviteAllUsers = () => {

      state.ui.errors.errorList.errorList = []
      state.ui.errors.hasError = false;
      // if (state.data.subscriptionDetails.total_channel_member === state.userList.length) {
      //   // if (1 === state.activeMemberList.length) {

      //   state.ui.isActive.upgradeSubscription = true;
      // } else {
      // state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.sendChannelId = [];
      state.selectedAllChannel.forEach((item: any) => {
        state.sendChannelId.push(item);
      });
      // state.invite.users.forEach((item: any) => {
      //   state.invite.filteredUsers.push(item.email);
      // });
      state.invite.invites.forEach((user: any) => {
        const data = {
          email: user.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: user.first_name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: user.last_name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone: (user.phone.length == 10) ? "1" + user.phone : user.phone,
          mailNotification: user.mailNotification,
          smsNotification: user.smsNotification,
        };
        state.invite.filteredInvites.push(data);
      });
      if (state.inviteMember && state.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          state.invite.filteredInvites.push(state.inviteMember);
        }
      }
      validateInviteForm();
      if (
        state.ui.errors.errorList.errorList.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.inviteAllUsers
      )
        return false;
      let payLoad: any = {};

      payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: props.projectUUID,
        channels: state.sendChannelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "channel_invitation",
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_type: state.data.inviteAsGuest ? "guest" : "",
        // users: common.removeDuplicate(state.invite.filteredUsers),
        invites: common.removeDuplicate(state.invite.filteredInvites),
        // eslint-disable-next-line @typescript-eslint/camelcase
        message_share: {
          type: selectedValue.value,
        }
      };
      if (selectedValue.value === "4") {
        payLoad.message_share.date = selectedDateForMember.value;
        // selectedDateForMember
      }

      state.ui.isPostLoading.inviteAllUsers = true;
      console.log("CHECK PAYLOAD >>> ", payLoad)
      ethitransService
      .invitation(payLoad)
      .then((res: any) => {
        // console.log("Users Invite Check:>>> ", res.data.message);
        hideChatChannelInviteMember();
        // trigger successful modal from parent modal ie. chatchannel or project details
        // showSuccessModal();
        emit("invite", res.data.message);
        emit("setPendingList", common.removeDuplicate(state.invite.filteredInvites));
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.invitationMessage = res.data.message ? res.data.message : "";
          getProjectChannel();
        })
        .catch((error: any) => {
        common
          .sdCatchErr(error, state.ui.errors.errorList.errorList)
          .then((res: any) => {
            state.ui.errors.hasError = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.ui.errors.errorList.errorList.push(value[0]);
              }
            }
          });
      })
        .finally(() => {
          state.ui.isPostLoading.inviteAllUsers = false;
        });
      // }
    }

    onMounted(() => {
      if (props.showModal) {
        $("#chatChannelInviteMember").modal("show");
        addNewMember();
        projectMemberListByChannel();
        if (props.channelUUID) {
          state.data.showCheckChannelMessage = true;
          state.selectedAllChannel.push(props.channelUUID);
        }
      }
    });
    return {
      state,
      checkEmptyFields,
      handleFilteredObjects,
      hideChatChannelInviteMember,
      projectMemberListByChannel,
      allChannels,
      allUsers,
      selectedUsers,
      nameWithEmail,
      selectToChangeUsers,
      fetchCustomerDetails,
      hasValidLastContact,
      addNewMember,
      removeSelectedMember,
      removeSelectedUser,
      selectedValue,
      // getChannelByProject,
      showCreateProjectChannel,
      selectedDateForMember,
      changeInvitePublicMember,
      deletePublicMember,
      inviteAllPublicUser,
      inviteAllUsers,
    };
  },
});
