
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  onMounted,
  watch,
  onBeforeMount,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { signOut } from "@/common/google";
import { config } from "@/Config";
import { ethitransService } from "@/services/EthitransService";
import NotificationModal from "@/components/modal/NotificationModal.vue";
import { common } from "@/common/services/Common";
import Pusher from "pusher-js";
import $ from "jquery";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import FeedbackModal from "@/components/modal/FeedbackModal.vue";
import UpdateToNormalModal from "@/components/modal/UpdateToNormalModal.vue";
import MobileVerification from "@/components/modal/MobileVerification.vue";
import { useCookie } from 'vue-cookie-next';
import { useI18n } from 'vue-i18n';
// import Profile from "@/components/channel/Profile.vue";

import InternetSpeed from "@/includes/InternetSpeed.vue";

// import 'bootstrap';

export default defineComponent({
  name: "TheNavBar",
  components: {
    InternetSpeed,
    NotificationModal,
    SuccessfulModalCustom,
    FeedbackModal,
    MobileVerification,
    UpdateToNormalModal
  },
  setup() {
    const cookie = useCookie()
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      data: {
        showUpdateToNormalModal:false,
        showFeedBackModal: false,
        showMobileVerification: false,
        showSuccesCustomModal: false,
        tourGuide: false,
        logOutButton: false,
        showNotifications: false,
        invitationReload: false,
        showNotificationModal: false,
        successFulMessage: "",
        channelId: "",
        channelActivitiesList: [],
        channelActivitiesListCount: 0,
        isGuest: 0,
        userType: localStorage.getItem("current_user_role"),
        time: 100,
        notification: {
          sms: false,
          email: false,
        },
        error: {
          errorsList: [],
          hasError: false,
        },
        phone: {
          showStatus: false,
          countryCode: "",
          collection: [] as any,
          number: "",
          code: "+1",
          mobileCode: "+1",
          otp: "",
          isVerify: false,
        },
        language: "en" as any,
        languages: [
          // {
          //   name: "Skype",
          //   value: "skype",
          // },
          {
            name: "English",
            value: "en",
          },
          // {
          //   name: "Zoom",
          //   value: "zoom",
          // },
          {
            name: "Spanish",
            value: "es",
          },
        ]
      },
      ui: {
        error: {
          errorsList: [] as any,
          hasError: false,
        },
        isLoading: {
          clientDocumentRetrieveWorkflow: false,
          userStatus: false,
        },
        isPostLoading: {
          updateUserNotification: false,
          toogleGuideTour: false,
        },
      },
      imageBaseUrl: "",
      activeUser: "" as any,
      activeUserImage: "" as any,
      activeUserEmail: "" as any,
      activeUserDetails: {} as any,
      userInfo: "" as any,
      countryCodeList: [] as any,
    });
    const { t, locale } = useI18n();
    const isMobileDevice = computed(() => {
      return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;
    });
    function showUpdateToNormalModal() {
      state.data.showUpdateToNormalModal = !state.data.showUpdateToNormalModal;
    }
    function showFeedBackModal() {
      state.data.showFeedBackModal = !state.data.showFeedBackModal;
    }
    function hideFeedBackModal() {
      state.data.showFeedBackModal = !state.data.showFeedBackModal;
    }
    function hideUpdateToNormalModal(){
      state.data.showUpdateToNormalModal = !state.data.showUpdateToNormalModal;
    }

    function showMobileVerificationModal() {
      state.data.showMobileVerification = !state.data.showMobileVerification;
    }
    function hideMobileVerificationModal(data: any) {
      state.userInfo = data;
      state.data.showMobileVerification = !state.data.showMobileVerification;
    }

    function userAvatar() {
      if (store.state.showTourGuide) {
        state.data.tourGuide = true;
      } else {
        state.data.tourGuide = false;
      }
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      // $("#SuccessfulModalCustom").modal("hide");
    }

    function langChange(){
      locale.value = state.data.language;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        lang: state.data.language,
      };
      ethitransService.updateUserlang(payLoad).then((res: any)=>{
        store.dispatch("saveLanguage",state.data.language);
      });
    }
    function toogleGuideTour() {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.toogleGuideTour) return false;
      let isTourGuide = 0;
      if (state.data.tourGuide) {
        isTourGuide = 1;
      } else {
        isTourGuide = 0;
      }
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_tour_guide: isTourGuide,
      };
      state.ui.isPostLoading.toogleGuideTour = true;
      ethitransService
        .tourGuideUpdate(payLoad)
        .then((res: any) => {
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          store.dispatch("toggleTourGuide", res.data.data.is_tour_guide);
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          if (
            res.data.data.is_tour_guide === 1 ||
            res.data.data.is_tour_guide === "1"
          ) {
            state.data.tourGuide = true;
            state.data.successFulMessage = "Tour Guide Successfully Turned ON.";
          } else {
            state.data.tourGuide = false;
            state.data.successFulMessage =
              "Tour Guide Successfully Turned OFF.";
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK for Error >>>", res.error[0])
              //     // state.errorList.push(res.email[0]);
              //     // state.errorList = [...state.errorList]
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.toogleGuideTour = false;
        });
    }
    // setInterval(function(){ console.log("Hello"); }, 180000);

    // setTimeout(checkStatus, state.data.time);
    function showNotificationModal() {
      state.data.showNotificationModal = !state.data.showNotificationModal;
    }
    function hideNotifications() {
      // alert('NOTIFICAION');
      state.data.showNotifications = false;
    }
    function resetNotificationShow() {
      setTimeout(hideNotifications, 60000);
    }
    function showNotificationList() {
      // alert("Clicked")
      state.data.showNotifications = !state.data.showNotifications;
      if (state.data.showNotifications) {
        resetNotificationShow();
      }
    }
    // window.addEventListener('beforeunload', function (e) {
    //   //Open popup here
    //   confirm("Press a Button")
    // // $("#closeConvorally").modal("show");
    // // window.stop();
    //   // Cancel the event
    //   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //   // Chrome requires returnValue to be set
    //   e.returnValue = '';
    // });
    // window.onbeforeunload = function(e: any) {
    // //   // closeConvorally
    // // //Open popup here
    // // $("#closeConvorally").modal("show");
    // // window.stop();
    // const msg = 'Do you really want to close?'
    // return msg;
    // };
    //     window.onbeforeunload = function () {

    // };

    // window.onunload = () => {
    //   // Clear the local storage
    //   window.localStorage.clear();
    // };
    // provide('reload', state.data.invitationReload)

    const activities = computed(() => {
      return state.data.channelActivitiesList;
    });

    const notificationCount = computed(() => {
      if (localStorage.getItem("current_user_info")) {
        const userData: any = localStorage.getItem("current_user_info");
        // console.log("<<< Tour Status >>> ", JSON.parse(userData).is_tour_guide)
        store.dispatch("toggleTourGuide", JSON.parse(userData).is_tour_guide);
      }
      return state.data.channelActivitiesList.length;
    });

    // const url: any = window.location.href;
    //   // const url: any = window.location.href.split("#/").pop();
    //   const page: any = url
    //     .split("#/")
    //     .pop()
    //     .split("/")[0];
    //   const channelId: any = url.split("/").pop();
    //   // console.log("URL Check >>> ",url)
    //   if (page === "channel") {
    //     console.log("Component >>> ", page);
    //     console.log("Channel ID Check >>> ", channelId);
    //     state.data.channelId = channelId;
    //   } else {
    //     state.data.channelId = '';

    //   }

    const connStatus = computed(() => {
      if (navigator.onLine) {
        return "Connected to internet";
      } else {
        return "Unable to connect to internet";
      }
    });
    // const channelUUID = computed(() => {
    //   const url: any = window.location.href;
    //   // const url: any = window.location.href.split("#/").pop();
    //   const page: any = url
    //     .split("#/")
    //     .pop()
    //     .split("/")[0];
    //   const channelId: any = url.split("/").pop();
    //   console.log("URL Check >>> ",url , page)
    //   if (page === "channel") {
    //     // console.log("Component >>> ",page)
    //     console.log("Channel ID Check >>> ", channelId);
    //     // state.data.channelId = channelId
    //     return channelId;
    //   } else {
    //     console.log("EMPTY");
    //     return "";
    //   }
    // });
    function toggleDropdown() {
      // console.log("drop");
      $(".dropdown-toggle").dropdown();
      $().dropdown("update");
      $().dropdown("dispose");
    }
    // function reloadPage() {
    //   // The last "domLoading" Time //
    //   // eslint-disable-next-line no-var
    //   var currentDocumentTimestamp = new Date(
    //     performance.timing.domLoading
    //   ).getTime();
    //   // Current Time //
    //   // eslint-disable-next-line no-var
    //   var now = Date.now();
    //   // Ten Seconds //
    //   // eslint-disable-next-line no-var
    //   var tenSec = 10 * 1000;
    //   // Plus Ten Seconds //
    //   // eslint-disable-next-line no-var
    //   var plusTenSec = currentDocumentTimestamp + tenSec;
    //   if (now > plusTenSec) {
    //     location.reload();
    //     // eslint-disable-next-line no-empty
    //   } else {
    //   }
    // }
    function closeNotificationModal() {
      state.data.showNotificationModal = !state.data.showNotificationModal;
    }
    function channelNotification() {
      state.data.channelActivitiesList = [];
      state.data.channelActivitiesListCount = 0;
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.channelId ? state.data.channelId : "",
      };
      // state.postLoading = true;
      ethitransService
        .channelNotificationList(payLoad)
        .then((res: any) => {
          state.data.channelActivitiesList = res.data.data ? res.data.data : [];
          // console.log("HELLO >>>>", state.data.channelActivitiesList);
          let messageCount = 0;
          const invitationObjects: any = [];
          state.data.channelActivitiesList.forEach((item: any) => {
            // console.log("CHECK NOTIFICATION >>> ", item);
            if (item.type === 1) {
              if (
                item.module === "channel_invitation" &&
                item.invitation_type === "created"
              ) {
                // location.reload();
                invitationObjects.push(item);
              }
            }
            if (item.type === 2 || item.type === 4 || item.type === 6) {
              // location.reload();
              invitationObjects.push(item);
              // }
            }
            // date 26 Dec type 20 added
            if (item.type === 11 || item.type === 15 || item.type === 20) {
              invitationObjects.push(item);
            }
            // if (
            //   item.module === "channel_invitation" &&
            //   item.invitation_type === "created"
            // ) {
            //   // location.reload();
            //   invitationObjects.push(item);
            // }
            if (item.message_count) {
              messageCount += item.message_count;
            }
          });
          // reloadPage()
          localStorage.setItem("userInvitationCount", invitationObjects.length);
          store.dispatch("saveInvitationCount", invitationObjects.length);
          state.data.channelActivitiesListCount =
            state.data.channelActivitiesList.length;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // if (
          //   state.data.channelActivitiesList &&
          //   state.data.channelActivitiesList.length > 0
          // ) {
          //   console.log(
          //     "CHECK Length>>> ",
          //     state.data.channelActivitiesList.lengths
          //   );
          //   if (state.data.channelActivitiesList.length > 0) return false;
          //   const audio = new Audio("audio/pristine-609.mp3");
          //   audio.play();
          // }
          // state.postLoading = false;
        });
    }

    //  state.data.channelActivitiesList.forEach((item: any) => {
    //   console.log("CHECK NOTIFICATION >>>> ", item)
    // })

    function notifyReadNotification(notificationId: any) {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        notification_id: notificationId.uuid,
      };
      // state.postLoading = true;
      ethitransService
        .notificationReadInChannel(payLoad)
        .then((res: any) => {
          state.data.channelActivitiesList =
            state.data.channelActivitiesList.filter(
              (item: any) => item != notificationId
            );
          console.log("channelNotification Response >>> ", notificationId);
          if (notificationId.type === 1) {
            if (
              notificationId.link === "invitation" &&
              notificationId.module === "channel_invitation" &&
              notificationId.invitation_type === "created"
            ) {
              router.push({
                name: "admin-invitation",
              });
            } else if (
              notificationId.link === "invitation" &&
              notificationId.module === "channel_invitation" &&
              notificationId.invitation_type === "joined"
            ) {
              // router.push({
              //   name: "admin-dashboard",
              // });
            }
            if (
              notificationId.module === "channel_communication" &&
              notificationId.channel &&
              notificationId.channel.uuid
            ) {
              // window.location.replace("http://www.w3schools.com");
              router.push({
                name: "admin-channel",
                params: { channelId: notificationId.channel.uuid },
              });
            }

            if (notificationId.communication_id) {
              router.push({
                name: "admin-channel",
                params: { channelId: notificationId.channel.uuid },
                // eslint-disable-next-line @typescript-eslint/camelcase
                query: { communication_id: notificationId.communication_id },
              });
            }
          }
          else if (notificationId.type === 4) {
            router.push({
              name: "admin-invitation",
              query: { id: notificationId.related_id },
            });
          }
          else if (notificationId.type === 6) {
            router.push({
              name: "user-phase-task-invitation-acknowledgement",
              params: { invitationId: notificationId.related_id },
            });
          }
          else if (notificationId.type === 2) {
            router.push({
              name: "user-project-acknowledgement",
              params: { invitationId: notificationId.related_id },
            });
          }
          else if (notificationId.type === 3) {
            // not required for 3
            router.push({
              name: "smart-contract-list",
              params: { projectId: notificationId.related_id },
            });
            // router.push({
            //   name: "admin-project-details",
            //   params: { projectId: notificationId.related_id },
            // });
          }
          // redirect to phase details with ID
          else if (
            notificationId.type === 5 ||
            notificationId.type === 7 ||
            notificationId.type === 10
          ) {
            // not required for 3
            router.push({
              name: "user-todo-details",
              params: { phaseId: notificationId.related_id },
            });
          }
          // updated 26 Dec
          else if (notificationId.type === 15) {
            // not required for 3
            router.push({
              name: "invite-job-estimation-details",
              params: { estimationId: notificationId.related_id },
            });
          }
          // redirect to task update notify detail with ID
          else if (
            notificationId.type === 19
          ) {
            // not required for 3
            router.push({
              name: "todo-task-update-notify-details",
              params: { phaseId: notificationId.related_id },
            });
          }
          else if (
            notificationId.type === 17
          ) {
            router.push({
              name: "list-job-estimation",
              params: { projectId: notificationId.related_id },
            });
          }
          else if (
            notificationId.type === 22
          ) {
            router.push({
              name: "user-phase-invoice",
              params: { id: notificationId.related_id },
            });
          }
          // if (
          //   notificationId.link === "invitation" &&
          //   notificationId.module === "channel_invitation" &&
          //   notificationId.invitation_type === "created"
          // ) {
          //   router.push({
          //     name: "admin-invitation",
          //   });
          // } else if (
          //   notificationId.link === "invitation" &&
          //   notificationId.module === "channel_invitation" &&
          //   notificationId.invitation_type === "joined"
          // ) {
          //   // router.push({
          //   //   name: "admin-dashboard",
          //   // });
          // } else {
          //   // notifyReadNotification(selectedRow)
          //   router.push({
          //     name: "admin-channel",
          //     params: { channelId: notificationId.channel.uuid },
          //   });
          // }
          if (notificationId.module == 'channel_communication_pdf') {
            const filename = decodeURIComponent(notificationId.link.split("/").pop());
            const link = document.createElement('a');
            link.href = notificationId.link;
            link.download = filename;

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger a click on the link
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

          }
          hideNotifications();
          showNotificationList();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
          //     router.push({
          //   name: "admin-channel",
          //   params: { channelId: notificationId.channel.uuid },
          // });
          channelNotification();
        });
    }

    //read all notifications

    function notifyAllReadNotification(notifictaion: any) {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      const payLoad = {
        notifications: notifictaion,
      };
      // state.postLoading = true;
      ethitransService
        .notificationAllReadInChannel(payLoad)
        .then((res: any) => {
          hideNotifications();
          showNotificationList();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {

          // state.postLoading = false;
          //     router.push({
          //   name: "admin-channel",
          //   params: { channelId: notificationId.channel.uuid },
          // });
          channelNotification();
        });
    }

    function redirectToChannel(selectedRow: any) {
      if (!selectedRow.uuid) return false;
      // if (!selectedRow.channel.uuid && !selectedRow.uuid) return false;
      notifyReadNotification(selectedRow);
    }

    // // Pusher.logToConsole = true;
    // const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
    //   cluster: "ap2",
    // });

    // const channel = pusher.subscribe(`channelNotification`);
    // channel.bind("channelNotification.created", function (data: any) {
    //   // console.log('PUSHER CHECK', data);

    //   if (JSON.stringify(data)) {
    //     const url: any = window.location.href;
    //     // const url: any = window.location.href.split("#/").pop();
    //     const page: any = url.split("#/").pop().split("/")[0];
    //     const channelId: any = url.split("/").pop();
    //     state.data.channelId = "";
    //     if (page === "channel") {
    //       state.data.channelId = channelId;
    //     } else if (page === "invitation") {
    //       window.location.reload();
    //     } else {
    //       state.data.channelId = "";
    //     }
    //     channelNotification();
    //   }
    // });

    // private channel pusher implemented
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + newToken,
        },
      },
    });

    const userInfo: any = localStorage.getItem("current_user_info");
    // console.log("USER ID CHECK >>> ", JSON.parse(userInfo).uuid)
    const channelPrivate = pusher.subscribe(
      `private-userNotification.${JSON.parse(userInfo).uuid}`
    );
    // console.log("Channel Communication>>> ", channel);
    channelPrivate.bind("userNotification.created", function (data: any) {
      if (JSON.stringify(data)) {
        state.data.channelId = "";
        const url: any = window.location.href;
        // const url: any = window.location.href.split("#/").pop();
        const page: any = url.split("#/").pop().split("/")[0];
        const channelId: any = url.split("/").pop();
        if (page === "channel") {
          state.data.channelId = channelId;
        } else if (page === "invitation") {
          // window.location.reload();
        } else {
          state.data.channelId = "";
        }
        channelNotification();
      }
    });

    const userLanUpdate = pusher.subscribe(
      `private-userLangUpdate.${JSON.parse(userInfo).uuid}`
    );

    userLanUpdate.bind("userLangUpdate.created",function(data: any){
      if(data.user.lang != store.state.language){
        state.data.language = data.user.lang;
        locale.value = state.data.language;
        store.dispatch("saveLanguage",data.user.lang);
      }
    });


    const channelPdfPrivate = pusher.subscribe(
      `private-userPdfNotification.${JSON.parse(userInfo).uuid}`
    );
    // console.log("Channel Communication>>> ", channel);

    channelPdfPrivate.bind("userPdfNotification.created", function (data: any) {
      if (JSON.stringify(data)) {
        state.data.channelId = "";
        channelNotification();
      }
    });
    // localStorage.getItem('current_user_uuid')
    function toggleSidebar(event: any) {
      // alert()
      event.preventDefault();
      $("body").toggleClass("sb-sidenav-toggled");
    }
    function updateUserNotification() {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        email_notification: state.data.notification.email ? 1 : 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sms_notification: state.data.notification.sms ? 1 : 0,
      };
      state.ui.isPostLoading.updateUserNotification = true;
      ethitransService
        .updateUserNotification(payLoad)
        .then((res: any) => {
          $("#notificationModal").modal("hide");
          console.log(
            "updateUserNotification Response Check:>>> ",
            res.data.data
          );
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          const userDetails: any = localStorage.getItem("current_user_info");
          if (userDetails) {
            state.activeUserDetails = JSON.parse(userDetails);
          }
          // console.log("Data Check >>>", state.activeUserDetails);
          if (state.activeUserDetails) {
            if (
              state.activeUserDetails.email_notification === 1 ||
              state.activeUserDetails.email_notification === "1"
            ) {
              state.data.notification.email = true;
            } else {
              state.data.notification.email = false;
            }

            if (
              state.activeUserDetails.sms_notification === 1 ||
              state.activeUserDetails.sms_notification === "1"
            ) {
              state.data.notification.sms = true;
            } else {
              state.data.notification.sms = false;
            }
          }
          // notificationModal.hide()
          // $("#notificationModal").modal("hide");
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.updateUserNotification = true;
        });
    }

    function logOut() {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      // console.log("FrontEnd Logout");
      ethitransService
        .logOut()
        .then((res: any) => {
          // Clear all cookies
          // cookie.clearCookie();
          // cookie.removeCookie('theme');
          // console.log("Res Logout", res);
          location.replace(`${config.logOutUrl}#/login`);
          signOut();
          const isTourActive: any = localStorage.getItem("isTourActive");
          const username: any = localStorage.getItem("current_user_username");
          const password: any = localStorage.getItem("current_user_password");
          const rememberPassword = localStorage.getItem(
            "current_user_rememberPassword"
          );
          // console.log(username, password);

          localStorage.clear();
          
          if (rememberPassword === "true") {
            localStorage.setItem("isTourActive", isTourActive);
            localStorage.setItem("current_user_username", username);
            localStorage.setItem("current_user_password", password);
            localStorage.setItem(
              "current_user_rememberPassword",
              rememberPassword
            );
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // location.reload();
          // state.data.logOutButton = false;
          // // state.ui.isPostLoading.replyMessage = false;
        });
    }
    function logOutNav() {
      // if (state.data.logOutButton) return false;
      // // alert("HELLO")
      // state.data.logOutButton = true;
      logOut();
      // common.logOut();
    }
    // window.addEventListener("beforeunload", function(event) {
    //   logOut();
    // });
    // window.addEventListener("unload", function(event) {
    //   logOut();
    // });

    // const inactivityTime = function () {
    //   let time: any;
    //   function logout() {
    //     console.log("You are now logged out.");
    //     common.logOut();
    //     //location.href = 'logout.html'
    //   }
    //   function resetTimer() {
    //     // console.log("FrontEnd Logout Time reset");
    //     clearTimeout(time);
    //     // time = setTimeout(logout, 5400000); // 30 min
    //     // time = setTimeout(logout, 180000); // 3 min
    //     // console.log("TIME CHECK>>> ", time)
    //     // time = setTimeout(logout, 600000); // 10 min
    //     time = setTimeout(logout, 60000*60*8); //  8 hrs active
    //     // time = setTimeout(logout, 240000); // 4 min
    //     // 1000 milliseconds = 1 second
    //   }
    //   window.onload = resetTimer;
    //   window.onmousemove = resetTimer;
    //   window.onmousedown = resetTimer; // catches touchscreen presses as well
    //   window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    //   window.onclick = resetTimer; // catches touchpad clicks as well
    //   window.onkeydown = resetTimer;
    //   // document.addEventListener("keypress", function (event: any) {
    //   //   // if (event.keyCode == 13) {
    //   //   // alert("hi.");
    //   //   resetTimer;
    //   //   // }
    //   // });
    //   window.addEventListener("scroll", resetTimer, true);
    // };
    function updatedToNoraml(){
        state.data.isGuest = 0;
        hideUpdateToNormalModal();
    }
    function redirectToProfile() {
      router.push({
        name: "admin-profile",
      });
    }
    function userStatus() {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      const payLoad = {
        email: localStorage.getItem("current_user_email"),
      };
      state.ui.isLoading.userStatus = true;
      ethitransService
        .userStatus(payLoad)
        .then((res: any) => {
          if (
            res.data.data &&
            res.data.data.online_status.toString() === "offline"
          ) {
            common.logOut();
          } else {
            channelNotification();
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK for Error >>>", res.error[0])
              //     // state.errorList.push(res.email[0]);
              //     // state.errorList = [...state.errorList]
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isLoading.userStatus = true;
        });
    }
    watch(
      () => store.state.socketStatus,
      (newvalue, oldvalue) => {
        if(newvalue == 2){
          logOut();
        }
      }
    );

    const profileImage = computed(() => store.state.profileImage ? store.state.profileImage : state.activeUserImage);
    onMounted(() => {
      state.data.showNotifications = false;
      // inactivityTime();
      state.imageBaseUrl = config.imageBaseUrl;
      state.activeUser = localStorage.getItem("current_user_details");
      state.activeUserImage = localStorage.getItem("current_user_image");
      state.activeUserEmail = localStorage.getItem("current_user_email");
      userStatus();
      store.dispatch("showDefaultTourGuide");
      const userInfo: any = localStorage.getItem("current_user_info");
      state.userInfo = JSON.parse(userInfo);
      state.data.isGuest = state.userInfo.is_guest;
      state.data.language = store.state.language;
      // langChange();
    });
    return {
      state,
      isMobileDevice,
      hideFeedBackModal,
      showFeedBackModal,
      closeSuccessCustomModal,
      userAvatar,
      toogleGuideTour,
      logOutNav,
      userStatus,
      hideNotifications,
      resetNotificationShow,
      showNotificationList,
      notificationCount,
      // isBrowserClosed,
      // inactivityTime,
      activities,
      toggleDropdown,
      // channelUUID,
      redirectToChannel,
      notifyReadNotification,
      channelNotification,
      logOut,
      toggleSidebar,
      redirectToProfile,
      connStatus,
      // reloadPage,
      updateUserNotification,
      showNotificationModal,
      closeNotificationModal,
      notifyAllReadNotification,
      profileImage,
      showMobileVerificationModal,
      hideMobileVerificationModal,
      langChange,
      showUpdateToNormalModal,
      hideUpdateToNormalModal,
      updatedToNoraml,
      t
    };
  },
});
