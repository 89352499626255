
import { defineComponent, onMounted, reactive } from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import { config } from "@/Config";
import { useRoute } from "vue-router";
// import Axios from "axios";
// @ts-ignore-start
import handleClientLoad = require("../../../public/js/calendarAuth.js");
// @ts-ignore-end
// const handleClientLoad =  import("../../../public/js/calendarAuth");

export default defineComponent({
  name: "AppointmentImportModal",
  props: {
    showModal: Boolean,
  },
  components: {
    ErrorList,
    SuccessfulModalCustom,
  },
  setup(props, { emit }) {
    const gapi = window.gapi;
    const router = useRoute();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
  
    const state = reactive({
      data: {
        showSuccesCustomModal: false,
        SuccessFulMessage: "",

        range: {
          start: new Date(),
          end: new Date(),
        },
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        masks: {
          input: "YYYY-MM-DD h:mm A",
        },
        meetingVia: [
          {
            name: "Google",
            value: "google",
          },
          {
            name: "Yahoo",
            value: "yahoo",
          },
          {
            name: "Outlook",
            value: "outlook",
          },
          {
            name: "ICloud",
            value: "icloud",
          },
        ],
        syncVia: [
          {
            name: "Google",
            value: "google",
          },
          // {
          //   name: "ICloud",
          //   value: "icloud",
          // },
        ],
      },

      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
      routeValue: router.query.showModel,
      errorList: [] as any,
      isErrorStatus: false,
      postLoadingCreateAppointmen: false,
      meetingType: "" as any,
      title: "" as string,
      location: "" as string,
      note: "" as string,
      inviteUesr: [] as any,
      meetingUrl: "" as string,
      meetingCode: "" as string,
      googleMeetURL: "" as any,
      auth2: {},
      googleUser: {} as any,
      googleSignInParams: {
        /* eslint-disable @typescript-eslint/class-name-casing */
        /* eslint-disable @typescript-eslint/camelcase */
        client_id:
          "268712205523-dpbrhqpqu6cpbjdmp83ia9a7nhk2vu5k.apps.googleusercontent.com",
      },
      type: "" as any,
      file: "" as any,
      syncType: Number,
      postLoading : false,
      icloudLink: "" as any,
    });

    if (router.query.zoomMeeting === "true") {
      state.meetingType = "zoom";
    }
    //  function checkGoolgeLogin(){
    //    if(gapi.auth2.getAuthInstance().isSignedIn.get()){
    //      console.log("Google Logedin ");
    //      state.data.meetingVia.unshift({
    //           name: "Google",
    //           value: "google",

    //         },)
    //    }
    //   }

    //

    function syncFrom(){
      state.type = false;
      state.postLoading = true;
      const payLoad = { type: state.syncType}
        ethitransService.getAuthUrl(payLoad)
        .then((res: any) => {
          state.postLoading = false;
          if(res.data.data.type == 'redirect'){
            window.location.href = res.data.data.url;
          }else if(res.data.data.type == 'completed'){
            $("#appointmentCreateModel").modal("hide");
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.SuccessFulMessage = "Appointment Synced Successfully.";
            const data = res.data.data;
            emit("calendarData", data.data);
            
          }
        })
        .catch((error: any) => {
          //consoloe.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
          
        });
        
    }

    function closeSuccessCustomModal(){
      emit("importAppointment", "done");
    }

     // Event handler for file input change
     const handleFileChange = (event: any ) => {
      state.file = event.target.files[0];
    };
    
    //add new meeting using zoom

    function addZoomMeeting() {
      // state.postLoadingCreateAppointmen = true;

      return new Promise((resolve, reject) => {
        const payLoad = {
          type: state.type,
          note: state.note,
          startTime: state.data.range.start,
          endTime: state.data.range.end,
          token: localStorage.getItem("zoom_access_token"),
        };
        ethitransService
          .createZoomMeeting(payLoad)
          .then((res: any) => {
            console.log("zoom res", res);
            console.log("zoom res", res.data.join_url);
            state.googleMeetURL = res.data.join_url;
            resolve(state.googleMeetURL);
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            // state.postLoadingCreateAppointmen = false;
          });
      });
    }

    function closeAppointmentModal() {
      emit("importAppointment", "addAppointment success");
      $("#appointmentCreateModel").modal("hide");
    }

    function importAppointment() {
      emit("importAppointment", "addAppointment success");
      $("#appointmentCreateModel").modal("hide");
    }

    function validateForm() {
      
      state.errorList = [];
      state.isErrorStatus = false;
      if (!state.type) {
        state.errorList.push("Import from is required.");
      }
      if ( (state.type != 'icloud' ) && !state.file) {
        state.errorList.push("File is required.");
      }
      
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
    }
    function scrollToTop() {
      $("#appointmentModelScroll").animate({
        scrollTop: $("#appointmentModelScroll")[0],
      });
    }

    function googleLogin() {
      const myParams: any = {
        clientid:
          "268712205523-dpbrhqpqu6cpbjdmp83ia9a7nhk2vu5k.apps.googleusercontent.com", //You need to set client id
        cookiepolicy: "single_host_origin",
        // callback: "loginCallback", //callback function
        approvalprompt: "force",
        scope:
          "https://www.googleapis.com/auth/calendar",
      };
      // gapi.auth.signIn(myParams);
      handleClientLoad();
      gapi.auth2.getAuthInstance().signIn(myParams);
    }

    // zoom
    function checkLogin(event: any) {
      console.log(
        "login status",
        gapi.auth2.getAuthInstance().isSignedIn.get()
      );
      if (event === "zoom" && !localStorage.getItem("zoom_access_token")) {
        const zoomUrl = `${config.zoomAuth}?response_type=${config.response_type}&client_id=${config.client_id}&redirect_uri=${config.redirect_uri}`;
        // const zoomUrl = "https://zoom.us/oauth/authorize?response_type=code&client_id=ervA7HgFRAeJpdnqtVJoJg&redirect_uri=https://localhost:8080"
        window.open(zoomUrl, "_self");
      }
      // if (
      //   event === "google" &&
      //   !gapi.auth2.getAuthInstance().isSignedIn.get()
      // ) {
      //   console.log("im heree");
      //   googleLogin();
      // }
        if (
        event === "google"
      ) {
        console.log("im heree");
        googleLogin();
      }
    }

    function addNewAppointment() {
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.postLoadingCreateAppointmen
      ){
        return false;
      }
      const payLoad = new FormData();  
      payLoad.append('type',state.type);
      payLoad.append('file',state.file);
      payLoad.append('icloudUrl',state.icloudLink);
      // const payLoad = {
      //   type: state.type,
      //   file: state.file,
      // };
      state.postLoadingCreateAppointmen = true;
      ethitransService
        .importAppointment(payLoad)
        .then((res: any) => {
          $("#appointmentCreateModel").modal("hide");
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          const data = res.data.data;
          emit("calendarData", data);
          state.data.SuccessFulMessage = "Appointment Imported Successfully.";
        })
        .catch((error: any) => {
          console.log(error);
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.postLoadingCreateAppointmen = false;
        });
    }

    async function saveAppointment() {
      scrollToTop();
      validateForm();
      
      addNewAppointment();
    }

    onMounted(() => {
      console.log("CHECK >> ", typeof props.showModal);

      //  console.log('hello test',moment(state.data.range.start).format());

      if (props.showModal) {
        $("#appointmentCreateModel").modal("show");
      }
    });

    return {
      state,
      importAppointment,
      closeAppointmentModal,
      validateForm,
      addNewAppointment,
      scrollToTop,
      saveAppointment,
      checkLogin,
      addZoomMeeting,
      googleLogin,
      handleFileChange,
      syncFrom,
      closeSuccessCustomModal
    };
  },
});
