import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("ul", null, [
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-start'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep1 && _ctx.redirectToStep1(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 1 ", 1)
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-one'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep2 && _ctx.redirectToStep2(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 2 ", 1)
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-two'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep3 && _ctx.redirectToStep3(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 3", 1)
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-three'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep4 && _ctx.redirectToStep4(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 4", 1)
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-four'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep5 && _ctx.redirectToStep5(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 5", 1)
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-five'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep6 && _ctx.redirectToStep6(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 6", 1)
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-six'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep7 && _ctx.redirectToStep7(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 7", 1)
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-eight'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep8 && _ctx.redirectToStep8(...args)))
      }, "Step 8")
    ], 2),
    _createVNode("li", {
      class: _ctx.state.data.currentRoute === 'admin-tutorial-end'
                  ? 'active'
                  : ''
              
    }, [
      _createVNode("a", {
        href: "javascript:void(0)",
        onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.redirectToStep9 && _ctx.redirectToStep9(...args)))
      }, _toDisplayString(_ctx.$t('step')) + " 9", 1)
    ], 2)
  ]))
}